import React, { Component } from 'react';
import { photoEditorPropTypes, positionPropTypes } from '../../proptypes';
import Editar from '../../asset/editar.svg';
import PropTypes from 'prop-types';
import loading from '../../asset/image-loader.gif';

import WarnIcon from './warn-icon/';

import KeyboardEventHandler from 'react-keyboard-event-handler';

import { produtosEditaveis } from '../../utils/textosVariaveis';
import { getFundoOpacity } from '../../utils/calculosFundos';
import { isPrint } from '../../selector';

const fixPosAux = (
  pos,
  dim,
  shapeDim,
  id,
  rotate,
  fixPos,
  bleed,
  force,
  imgZoom
) => {
  const maxX =
    rotate === 0 || rotate === 180
      ? Math.abs(shapeDim.width - dim.width) * -1
      : Math.abs(shapeDim.height - dim.height) * -1;
  const maxY =
    rotate === 0 || rotate === 180
      ? Math.abs(shapeDim.height - dim.height) * -1
      : Math.abs(shapeDim.width - dim.width) * -1;

  if (pos.x < maxX || pos.y < maxY || force) {
    fixPos(pos, dim, shapeDim, id, rotate, bleed, imgZoom, force);
  }
};

const ShapeFormat = ({ width, height, type, points }) => {
  switch (type) {
    case 'ellipse':
      return (
        <ellipse
          cx={width / 2}
          cy={height / 2}
          rx={width / 2}
          ry={height / 2}
        />
      );
    case 'triangle':
      return <polygon points={`0,0 ${width},0 0,${height}`}></polygon>;
    case 'custom':
      return <polygon points={points}></polygon>;
    default:
      return <rect width={width} height={height} />;
  }
};

const checkFixPos = (props, prevProps, force = false) => {
  const {
    imgWidth,
    imgHeight,
    bleed,
    rotate,
    imgPos,
    zoom,
    width,
    height,
    id,
    fixPos
  } = props;
  if (
    (prevProps && (prevProps.rotate !== rotate || prevProps.zoom !== zoom)) ||
    force
  ) {
    const pos = {
      x: imgPos.x - bleed.left,
      y: imgPos.y - bleed.top
    };
    const imgZoom = zoom || 1;
    const imgRotate = rotate || 0;
    const imgScale =
      imgRotate === 0 || imgRotate === 180
        ? Math.max(width / imgWidth, height / imgHeight)
        : Math.max(width / imgHeight, height / imgWidth);
    const finalImgWidth = imgWidth * imgScale + bleed.left + bleed.right;
    const finalImgHeight = imgHeight * imgScale + bleed.top + bleed.bottom;

    if (prevProps) {
      const prevZoom = prevProps.zoom || 1;
      if (prevZoom !== zoom) {
        pos.x += (finalImgWidth * (prevZoom - (zoom || 1))) / 2;
        pos.y += (finalImgHeight * (prevZoom - (zoom || 1))) / 2;
      }
    }

    fixPosAux(
      pos,
      {
        width: finalImgWidth * imgZoom,
        height: finalImgHeight * imgZoom
      },
      imgRotate === 0 || imgRotate === 180
        ? { width, height }
        : { width: height, height: width },
      id,
      imgRotate,
      fixPos,
      bleed,
      (prevProps && prevProps.zoom !== zoom) || isPrint,
      imgZoom
    );
  }
};

class Frame extends Component {
  componentDidUpdate(prevProps) {
    checkFixPos(this.props, prevProps);
  }

  componentDidMount() {
    checkFixPos(this.props, null, true);
  }

  render() {
    const {
      espelhado,
      width,
      height,
      x,
      y,
      id,
      zoom,
      rotate,
      imgWidth,
      imgHeight,
      printSize,
      source,
      isDragging,
      isDraggingOver,
      mouseStartHandler,
      mouseStopHandler,
      moveHandler,
      moveKeyboardHandler,
      imgPos,
      bleed,
      type,
      connectDropTarget,
      photoEditor,
      updatePhotoEditor,
      isBookOverviewExpanded,
      isPrint,
      debru,
      changePos,
      screenRate,
      updateWarning,
      prodType,
      prodFormat,
      points
    } = this.props;

    const imgZoom = zoom || 1;
    let imgRotate = rotate || 0;

    const imgScale =
      imgRotate === 0 || imgRotate === 180
        ? Math.max(width / imgWidth, height / imgHeight)
        : Math.max(width / imgHeight, height / imgWidth);

    const pos = {
      x: imgPos.x - bleed.left,
      y: imgPos.y - bleed.top
    };
    const { active, frameId } = photoEditor;

    const finalImgWidth = imgWidth * imgScale + bleed.left + bleed.right;
    const finalImgHeight = imgHeight * imgScale + bleed.top + bleed.bottom;
    const loaderHeight = height * 0.075;

    const fixPosOnRotate =
      ((Math.abs(imgWidth - imgHeight) *
        imgScale *
        (imgWidth < imgHeight ? 1 : -1)) /
        2) *
      imgZoom;

    if (document.getElementById(`image-${id}`)) {
      if (active && frameId !== id) {
        document.getElementById(`image-${id}`).style.opacity = 0.25;
      } else {
        document.getElementById(`image-${id}`).style.opacity = '';
      }
    }

    let posicaoX = pos.x;
    let posicaoY = pos.y;
    let calculoRotacaoX =
      pos.x +
      (finalImgWidth * imgZoom) / 2 +
      (imgRotate === 90 ? fixPosOnRotate : 0);
    let calculoRotacaoY =
      pos.y +
      (finalImgHeight * imgZoom) / 2 -
      (imgRotate === 270 ? fixPosOnRotate : 0);

    return connectDropTarget(
      <svg
        id="connect-drop-target-id"
        width={width}
        height={height}
        x={x}
        y={y}
        style={{
          overflow: 'visible',
          cursor: `${
            !source
              ? 'progress'
              : isBookOverviewExpanded
              ? 'default'
              : active && frameId === id
              ? 'move'
              : 'pointer'
          } `
        }}
        opacity={getFundoOpacity()}
        onTouchStart={e => {
          if (!isBookOverviewExpanded && active && frameId === id && source) {
            mouseStartHandler(e, pos, true, id);
          }
        }}
        onMouseDown={e => {
          if (!isBookOverviewExpanded && active && frameId === id && source) {
            mouseStartHandler(e, pos, false, id);
          }
        }}
        onMouseUp={() => {
          if (
            isDragging &&
            !isBookOverviewExpanded &&
            active &&
            frameId === id &&
            source
          ) {
            mouseStopHandler();
          }
        }}
        onMouseLeave={() => {
          if (
            isDragging &&
            !isBookOverviewExpanded &&
            active &&
            frameId === id &&
            source
          ) {
            mouseStopHandler();
          }
        }}
        onTouchEnd={() => {
          if (
            isDragging &&
            !isBookOverviewExpanded &&
            active &&
            frameId === id &&
            source
          ) {
            mouseStopHandler();
          }
        }}
        onTouchMove={e => {
          if (
            isDraggingOver &&
            !isBookOverviewExpanded &&
            active &&
            frameId === id &&
            source
          ) {
            moveHandler(
              e,
              true,
              {
                width: finalImgWidth * imgZoom,
                height: finalImgHeight * imgZoom
              },
              imgRotate === 0 || imgRotate === 180
                ? { width, height }
                : { width: height, height: width },
              id,
              imgRotate,
              bleed,
              imgZoom
            );
          }
        }}
        onMouseMove={e => {
          if (
            isDraggingOver &&
            !isBookOverviewExpanded &&
            active &&
            frameId === id &&
            source
          ) {
            moveHandler(
              e,
              false,
              {
                width: finalImgWidth * imgZoom,
                height: finalImgHeight * imgZoom
              },
              imgRotate === 0 || imgRotate === 180
                ? { width, height }
                : { width: height, height: width },
              id,
              imgRotate,
              bleed,
              imgZoom
            );
          }
        }}
        onMouseOver={() => {
          if (!active && !isBookOverviewExpanded && source) {
            document.getElementById(`editar-${id}`).style.opacity = 1;
            document.getElementById(`frame-overlay-${id}`).style.opacity = 0.5;
            const mockupMask = document.getElementById('mockup-mask');
            if (mockupMask != null) mockupMask.style.opacity = 0;
          }
        }}
        onMouseOut={() => {
          if (!active && !isBookOverviewExpanded && source) {
            document.getElementById(`editar-${id}`).style.opacity = 0;
            document.getElementById(`frame-overlay-${id}`).style.opacity = 0;
            const mockupMask = document.getElementById('mockup-mask');
            if (mockupMask != null) mockupMask.style.opacity = 1;
          }
        }}
        onClick={() => {
          if (!active && !isBookOverviewExpanded && source) {
            updatePhotoEditor(id);
            document.getElementById(`editar-${id}`).style.opacity = 0;
            document.getElementById(`frame-overlay-${id}`).style.opacity = 0;
            const mockupMask = document.getElementById('mockup-mask');
            if (mockupMask != null) mockupMask.style.opacity = 1;
          }
        }}
      >
        <defs>
          <clipPath id={`mask-${id}`}>
            <ShapeFormat
              width={width}
              height={height}
              type={type}
              points={points}
            ></ShapeFormat>
          </clipPath>
        </defs>
        <KeyboardEventHandler
          handleKeys={[
            'left',
            'shift+left',
            'right',
            'shift+right',
            'up',
            'shift+up',
            'down',
            'shift+down',
            'e'
          ]}
          handleFocusableElements={true}
          onKeyEvent={key => {
            if (
              photoEditor.active &&
              active &&
              photoEditor.frameId === id &&
              !isBookOverviewExpanded &&
              source &&
              (key === 'left' ||
                key === 'right' ||
                key === 'down' ||
                key === 'up' ||
                key === 'shift+left' ||
                key === 'shift+right' ||
                key === 'shift+down' ||
                key === 'shift+up')
            ) {
              const keyString = key.length > 7 ? key.substring(6) : key;
              const step = key.length > 7 ? 10 : 100;

              moveKeyboardHandler(
                keyString,
                step,
                pos,
                {
                  width: finalImgWidth * imgZoom,
                  height: finalImgHeight * imgZoom
                },
                imgRotate === 0 || imgRotate === 180
                  ? { width, height }
                  : { width: height, height: width },
                id,
                imgRotate,
                bleed,
                imgZoom
              );
            } else if (
              key === 'e' &&
              produtosEditaveis() &&
              !photoEditor.active &&
              !active &&
              !isBookOverviewExpanded &&
              source
            )
              updatePhotoEditor(id);
          }}
        />
        {isDraggingOver && (
          <image
            xlinkHref={source}
            width={finalImgWidth * imgZoom}
            height={finalImgHeight * imgZoom}
            opacity="0.35"
            x={pos.x}
            y={pos.y}
            transform={`rotate(${imgRotate} ${calculoRotacaoX} ${calculoRotacaoY})`}
          />
        )}
        <g clipPath={`url(#mask-${id})`}>
          {source ? (
            <React.Fragment>
              <image
                xlinkHref={source}
                width={finalImgWidth * imgZoom}
                height={finalImgHeight * imgZoom}
                x={posicaoX}
                y={posicaoY}
                id={`image-${id}`}
                transform={`rotate(${imgRotate} ${calculoRotacaoX} ${calculoRotacaoY})`}
                opacity={isDragging && !isDraggingOver ? 0.125 : 1}
              />
              <rect
                id={`frame-overlay-${id}`}
                x="0"
                y="0"
                width={width}
                height={height}
                fill="#FFFFFF"
                opacity={0}
              />
            </React.Fragment>
          ) : (
            !isPrint && (
              <image
                xlinkHref={loading}
                height={loaderHeight}
                x={width / 2 - loaderHeight * 2 + (changePos.left ? debru : 0)}
                y={height / 2 - height * 0.1 + (changePos.top ? debru : 0)}
                id={`loader-${id}`}
              />
            )
          )}
        </g>
        {source && (
          <foreignObject
            width={width * 0.2}
            height={height * 0.2}
            x={width / 2 - width * 0.1 + (changePos.left ? debru : 0)}
            y={height / 2 - height * 0.1 + (changePos.top ? debru : 0)}
          >
            <img
              src={Editar}
              alt="Ícone editar"
              id={`editar-${id}`}
              draggable="false"
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                opacity: 0
              }}
            />
          </foreignObject>
        )}
        {!isPrint && !espelhado && (
          <WarnIcon
            id={id}
            width={width}
            height={height}
            screenRate={screenRate}
            printSize={printSize}
            changePos={changePos}
            bleed={bleed}
            type={type}
            debru={debru}
            photoEditorActive={photoEditor.active}
            updateWarning={updateWarning}
            prodType={prodType}
            prodFormat={prodFormat}
          />
        )}
      </svg>
    );
  }
}

Frame.propTypes = {
  espelhado: PropTypes.bool,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  zoom: PropTypes.number,
  rotate: PropTypes.number,
  imgWidth: PropTypes.number.isRequired,
  imgHeight: PropTypes.number.isRequired,
  printSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired
  }).isRequired,
  source: PropTypes.string.isRequired,
  isDragging: PropTypes.bool.isRequired,
  isDraggingOver: PropTypes.bool.isRequired,
  mouseStartHandler: PropTypes.func.isRequired,
  mouseStopHandler: PropTypes.func.isRequired,
  moveHandler: PropTypes.func.isRequired,
  moveKeyboardHandler: PropTypes.func.isRequired,
  fixPos: PropTypes.func.isRequired,
  imgPos: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  }).isRequired,
  bleed: positionPropTypes,
  type: PropTypes.string.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  photoEditor: photoEditorPropTypes,
  updatePhotoEditor: PropTypes.func.isRequired,
  isBookOverviewExpanded: PropTypes.bool.isRequired,
  isPrint: PropTypes.bool.isRequired,
  screenRate: PropTypes.number.isRequired,
  debru: PropTypes.number.isRequired,
  changePos: PropTypes.shape({
    top: PropTypes.bool.isRequired,
    left: PropTypes.bool.isRequired
  }).isRequired,
  prodFormat: PropTypes.string.isRequired,
  prodType: PropTypes.string.isRequired,
  fundoColor: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  points: PropTypes.string
};

export default Frame;
