import * as R from 'ramda';
import * as actionGeneral from '../action/general';
import * as actionItem from '../action/item';
import * as actionUI from '../action/ui';
import * as popupMessage from '../constant/popup-message';
import { movePage } from './movePage';
import {
  postMetadata,
  postMetadataFormat,
  postMetadataOrientation
} from './metadata';
import { duplicatePage } from './duplicatePage';
import { deleteImageFromBook, swapPoster } from './deleteImageFromBook';
import {
  getTipoQuantidadePadrao,
  getTipoPag,
  applyMethodTodos,
  getGeneroProduto
} from '../utils/textosVariaveis';
import {
  isCustomProduct,
  dropImageEmptySimplePage,
  dropImageEmptyPage,
  calcLastPageFlex
} from '../utils/separadorProdutos';
import {
  notUploadImage,
  samePageLayout,
  haveLastPageWarning
} from '../utils/separadorProdutos';
import { calculaSizeFamily, calculaSizeArrayFamily } from '../reducer/ui';
import { ALL } from '../constant/textStyle';
import {
  calcularPosicaoinicialImagem,
  tamanhoDefaulltTexto,
  getFamilyArray,
  isFix
} from '../utils/calculosGerais';

import {
  DELETE_ALL_IMAGES,
  ROTATE_IMAGE,
  DUPLICATE_PAGE,
  SAGA_DECREASE_PAGE_INDEX,
  SAGA_DELETE_IMAGE_FROM_BOOK,
  SAGA_FETCH_DATA,
  SAGA_FIRST_PAGE_INDEX,
  SAGA_INCREASE_PAGE_INDEX,
  SAGA_INPUT_FILE_CHANGED,
  SAGA_ADD_THEME_IMAGE,
  SAGA_INSERT_PAGES,
  SAGA_LAST_PAGE_INDEX,
  SAGA_MOVE_PAGE,
  SAGA_OVERVIEW_REMOVE_PAGES,
  SAGA_POST_IMAGE,
  SAGA_POST_METADATA,
  SAGA_REMOVE_PAGES,
  SAGA_REMOVE_TEXTS,
  SAGA_SET_LAYOUT,
  SAGA_SET_PAGE_INDEX,
  SAGA_SET_RICH_TEXT,
  SAGA_SHUFFLE_LAYOUT,
  SAGA_START_IMAGE_LOOP,
  SAGA_START_METADATA_LOOP,
  SAGA_SUBMIT,
  SAGA_THUMB_DROP_IMAGE,
  SAGA_UNSTAGE_STAGE_IMAGE,
  SAGA_UPDATE_THUMB_EXPAND,
  UPDATE_ZOOM,
  SAGA_SWAP_POSTER,
  SAGA_POST_METADATA_FORMAT,
  SAGA_POST_METADATA_ORIENTATION
} from '../constant/action-type/general';
import {
  MAX_HEIGHT,
  MAX_HEIGHT_REDUCED,
  MAX_WIDTH,
  MAX_WIDTH_REDUCED,
  NEXT,
  PREVIOUS
} from '../constant/thumb';
import { NAV_LAYOUTS_SELECTED, NAV_NONE_SELECTED } from '../constant/nav';
import {
  POPUP_IDENTIFY,
  POPUP_IDENTIFY_EMAIL,
  POPUP_ID_CONFIRM_REMOVE_PAGES,
  POPUP_ID_ERROR_LOAD,
  POPUP_ID_FILL_PAGES_PAGES_NEEDED,
  POPUP_ID_FILL_PAGES_PHOTOS_NEEDED,
  POPUP_ID_IMAGE_USED,
  POPUP_ID_LAYOUT,
  POPUP_ID_LOADING,
  POPUP_ID_NONE,
  POPUP_ID_REMOVE_PAGES,
  POPUP_ID_SENDING_IMAGES,
  POPUP_ID_SENDING_METADATA,
  POPUP_ID_UNABLE_LOAD_IMAGE,
  POPUP_ID_UNABLE_SET_LAST_PAGE_LAYOUT,
  POPUP_SCREEN_SIZE_ERROR,
  POPUP_FIREFOX_ANONIMO,
  POPUP_ID_SENDING_IMAGES_COMPLETE,
  POPUP_ID_MAX_USER_SPACE,
  POPUP_ID_MAX_IMAGE_COUNT
} from '../constant/popup';
import {
  SAGA_ADJUST_LAYOUTS_FILTER,
  SAGA_UPDATE_LAYOUT_FILTER_PHOTO_NUMBER,
  SET_IMAGES_UPLOADED,
  UPDATE_FILTER_METHOD,
  UPDATE_LAYOUT_FILTER_APPLY_METHOD,
  UPDATE_LAYOUT_FILTER_PHOTO_NUMBER,
  UPDATE_LAYOUT_FILTER_TEXT,
  UPDATE_PAGE_IDB,
  UPDATE_PAGE_SET_ID,
  UPDATE_SORT_METHOD
} from '../constant/action-type/item';
import {
  SET_STOP_DRAG,
  UPDATE_BOOK_OVERVIEW_EXPANDED,
  TOGGLE_MODO_IMPRESSAO,
  TOGGLE_BIBLIOTECA_TEMAS,
  SET_MODAL_CONFIG,
  UPDATE_GALLERY_EXPANDED,
  UPDATE_NAV_SELECTED,
  UPDATE_PHOTO_EDITOR,
  UPDATE_THUMB_EXPAND_IDB,
  UPDATE_TOOLBAR_PHOTO
} from '../constant/action-type/ui';
import {
  all,
  delay,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';
import {
  bookHaveCover,
  getAllBookPagesData,
  getAllImagesThumb,
  getBookNavigatorData,
  getBookNavigatorVisiblePagesLength,
  getEndpapersLength,
  getFilteredSortedImgs,
  getFormats,
  getFrames,
  getIDBStatus,
  getImagesData,
  getTotalImagesSize,
  getItemId,
  getItemIs3D,
  getLayoutsFilter,
  getNavigationIndex,
  getNotUploaded,
  getOverviewRemovePages,
  getPageSummary,
  getPaperSize,
  getPreviousNav,
  getPrintOptions,
  getPrintPage,
  getProductStyles,
  getProductType,
  getScreenSizeError,
  getShapesInBook,
  getTextOptions,
  getTextOptionsColeira,
  getThumbExpand,
  getToolbarPhoto,
  getUrls,
  isFinished,
  isPrint,
  isBack,
  isSpineModified,
  getFirstEmptyPage,
  getFirstEmptyTextPage,
  getProductSource,
  getUserProducts,
  getCustomProducts,
  getIsModoSobFivela,
  getDeckCardsPagesData,
  getNotUploadedAndUsed,
  getMaxUserDiskSpace,
  getMaxImageCount
} from '../selector';
import {
  getDefaultSetID,
  getNextSetId,
  getPageOrdenatedSetArr,
  getPreviousSetId,
  getSetDif,
  getSetNumber
} from '../selector/set';
import {
  getItem,
  getMetadatas,
  getServerImage,
  postItem,
  resetServerImage,
  fetchItemStatus,
  postLog
} from '../api';

import cuid from 'cuid';
import loadImage from 'blueimp-load-image';
import { normalizeImg } from '../schema/image';
import { normalizePage } from '../schema/page';
import normalizeServerItem from '../schema/server-item';
import { openDB } from 'idb';
import { thumbDropImageIdx } from '../utils/separadorProdutos';
import { LEFT, CENTER } from '../constant/textStyle';

function* identify() {
  const state = yield select();
  const prodType = getProductType(state);
  const productSource = getProductSource(state);
  //if((productSource === 'ML' && prodType === 'mask') || productSource === 'LP'){
  if (
    (productSource === 'ML' && prodType === 'mask') ||
    productSource === 'LP' ||
    process.env.NODE_ENV === 'development'
  ) {
    yield put(actionUI.setStateLegenda('NORMAL'));
    yield put(actionUI.updatePopup(POPUP_ID_NONE, false, ''));

    if (prodType === 'mask') {
      yield put(actionUI.updateTutorialStatus('PASSO'));
      yield put(actionUI.setStateLegenda('INTRODUCAO'));
    } else if (prodType === 'dog-collar') {
      yield put(actionUI.updateTutorialStatus('ESCOLHER-TAMANHO-COLEIRA'));
    }
  } else if (productSource === 'ML') {
    yield put(actionUI.setStateLegenda('POPUP_IDENTIFY_EMAIL'));
    yield put(
      actionUI.updatePopup(POPUP_IDENTIFY_EMAIL, true, popupMessage.IDENTIFY)
    );
  } else {
    yield put(actionUI.setStateLegenda('POPUP_IDENTIFY'));
    yield put(
      actionUI.updatePopup(POPUP_IDENTIFY, true, popupMessage.IDENTIFY)
    );
  }
}

function* fetchOrder({ payload: { id, pages, blankProduct, isFirefox } }) {
  yield put(actionUI.updatePopup(POPUP_ID_LOADING, false, 'Carregando'));
  const data = yield getItem(id, pages);

  if (data === undefined) {
    yield handleFetchOrderError();
  } else {
    try {
      yield handleFetchOrderSuccess(data);
    } catch {
      if (isFirefox)
        yield put(actionUI.updatePopup(POPUP_FIREFOX_ANONIMO, false, ''));
    }
  }

  if (!blankProduct) {
    // TODO Populate book
  }
}

const getEanBlob = eanUrl =>
  fetch(eanUrl)
    .then(res => res.blob())
    .then(blob => URL.createObjectURL(blob));

function* handleFetchOrderSuccess(data) {
  const { entities } = normalizeServerItem(data);
  const eanBlob = yield getEanBlob(data.settings.print.ean);

  yield put(actionGeneral.updateToFetchedData({ entities }));
  yield put(actionItem.updateOrderID(data.id));

  yield handleCustomProducts(data);

  yield put(actionItem.setEanBlob(eanBlob));

  const state = yield select();
  const screenSizeError = getScreenSizeError(state);
  const setID = getDefaultSetID(state);
  const pageFormat = getPaperSize(state);
  const prodType = getProductType(state);
  const isModoSobFivela = getIsModoSobFivela(state);

  if (prodType === 'poster')
    yield put(
      actionItem.resetLayoutFilterApplyMethod('somente ao Pôster atual')
    );
  else if (prodType === 'deck-cards')
    yield put(
      actionItem.resetLayoutFilterApplyMethod(
        isModoSobFivela ? 'a todas as cartas verso' : 'a todas as cartas frente'
      )
    );

  if (isPrint(state) && !getEndpapersLength(state))
    yield put(actionItem.setPageIndex(state.item.navigation.index - 1));

  yield put(actionItem.updatePageSetId(setID));
  yield put(actionItem.updatePageFormat(pageFormat));
  yield idbInit(data.id, screenSizeError);
  yield put(actionGeneral.startMetadataLoop(data.id));
  yield put(actionGeneral.startImageLoop());

  if (prodType === 'deck-cards' && isPrint(state)) {
    const back = isBack(state);
    if (back) yield put(actionItem.setModoSobFivela(true));
    else yield put(actionItem.setModoSobFivela(false));
  }
}

function* handleCustomProducts(data) {
  // custom products
  if (data.settings.products) {
    yield put(actionGeneral.setCustomProducts(data.settings.products));
    yield put(actionItem.setSelectedProduct(data.settings.products[0]));
  }

  // user products
  if (data.settings.products && data.settings.products.length > 0)
    yield put(
      actionItem.setUserProduct(
        0,
        data.settings.products[0].id,
        data.settings.products,
        'ADD'
      )
    );
}

function* handleFetchOrderError() {
  yield put(
    actionUI.updatePopup(POPUP_ID_ERROR_LOAD, true, 'Não foi possível carregar')
  );
}

function* fetchServerImages(itemID, imagesData, notUploaded, finished) {
  const serverBlobs = yield all(
    R.map(
      item => !item.metadata.isTheme && getServerImage(itemID, item.id),
      R.filter(({ id }) => !R.includes(id, notUploaded), imagesData),
      imagesData
    )
  );

  const serverImages = R.filter(
    ({ id }) => !R.includes(id, notUploaded),
    imagesData
  ).map(
    (
      {
        id,
        metadata: { name, type },
        source: {
          image: { id: imageId },
          thumb: { id: thumbId }
        }
      },
      idx
    ) => {
      const source = { image: imageId, thumb: thumbId };
      const file = new File([serverBlobs[idx]], name, { type });
      return { id, source, file };
    }
  );

  const notUploadedProperly = [];
  const themeImages = [];
  const consistentServerImages = R.filter(({ id, file: { size } }) => {
    const imgData = R.find(R.propEq('id', id))(imagesData);
    const imgSize = R.path(['metadata', 'size'], imgData);

    if (imgData.metadata && imgData.metadata.isTheme) {
      themeImages.push(imgData);
      return false;
    }
    if (imgSize === size) return true;
    else {
      notUploadedProperly.push(id);
      return false;
    }
  }, serverImages);

  if (R.length(consistentServerImages)) {
    yield all(consistentServerImages.map(img => addImageFromDB(img)));
  }
  if (R.length(themeImages)) {
    for (let i = 0; i < themeImages.length; i++) {
      yield put(
        actionGeneral.updateBlobURL(
          themeImages[i].source.thumb.id,
          themeImages[i].metadata.thumbUrl
        )
      );
      yield put(
        actionGeneral.updateBlobURL(
          themeImages[i].source.image.id,
          themeImages[i].metadata.imageUrl
        )
      );
    }
  }
  if (R.length(notUploadedProperly) && !finished) {
    yield cleanImagesNotUploaded(notUploadedProperly);
  }
}

function* fetchImages(db, itemID) {
  const state = yield select();
  const print = isPrint(state);
  const finished = isFinished(state);
  const imagesData = getImagesData(state);
  const notUploaded = getNotUploaded(state);

  if (print) {
    const { frame, page } = state.library;
    const printPageIndex = getPrintPage(state);

    if (page) {
      const coverFrames = page.cover ? [...page.cover.frames] : [];
      const backCoverFrames = page.back_cover
        ? [...page.back_cover.frames]
        : [];
      const printPage =
        printPageIndex === 'cover' || printPageIndex === 'back_cover'
          ? { frames: [...coverFrames, ...backCoverFrames] }
          : page[printPageIndex - 1];
      if (printPage && frame) {
        const pageImagesId = R.map(
          R.prop('image'),
          R.values(R.filter(f => R.includes(f.id, printPage.frames), frame))
        );
        const pageImages = R.filter(
          f => R.includes(f.id, pageImagesId),
          imagesData
        );
        yield fetchServerImages(itemID, pageImages, notUploaded, finished);
      }
    }
  } else {
    yield fetchServerImages(itemID, imagesData, notUploaded, finished);
    if (R.length(notUploaded)) {
      if (db) {
        const idbImages = yield all(
          notUploaded.map(id => db.get('images', `${itemID}-${id}`))
        );
        yield all(
          idbImages.map(
            img => img != null && img.img != null && addImageFromDB(img.img)
          )
        );
        // * Clean Images not stored
        const idbImagesID = R.map(R.path(['img', 'id']), idbImages);
        const imgNotStored = R.filter(
          id => !R.includes(id, idbImagesID),
          notUploaded
        );
        if (!finished) {
          yield cleanImagesNotUploaded(imgNotStored);
        }
      } else {
        if (!finished) {
          yield cleanImagesNotUploaded();
        }
      }
    }
  }
}

const deleteOldEntries = async (db, itemId) => {
  const count = await db.count('metadata');
  if (count > 4) {
    try {
      const ids = (await db.getAllKeys('metadata')).slice(0, -4);
      const imageKeys = await db.getAllKeys('images');
      ids.map(async id => id !== itemId && (await db.delete('metadata', id)));
      imageKeys.map(
        async key =>
          ids.includes(parseInt(R.head(key.split('-')))) &&
          (await db.delete('images', key))
      );
    } catch (e) {
      const current = await db.get('metadata', itemId);
      await db.clear('metadata');
      await db.clear('images');
      if (current) {
        await db.add('metadata', current);
      }
    }
  }
};

function* idbInit(itemID, screenSizeError) {
  let db;
  let error = '';

  // INIT DB
  db = yield openDB('prin-app', 1, {
    upgrade(db) {
      db.createObjectStore('images', { keyPath: 'id' });
      db.createObjectStore('metadata', { keyPath: 'id' });
    },
    blocked() {
      error = 'blocked';
    },
    blocking() {
      error = 'blocking';
    },
    terminated() {
      error = 'terminated';
    }
  });

  deleteOldEntries(db, itemID);

  // Get Metadata from Server
  const serverMetadata = yield getMetadatas(itemID);
  let idbMetadata;
  let isFinished = false;

  // Check if is any error with IDB
  try {
    if (error.length) console.error(error);
    idbMetadata = yield db.get('metadata', itemID);
    yield put(actionUI.updateIDBStatus(true));
  } catch (e) {
    console.error(' Check if is any error with IDB', e);
  }

  // Update state
  if (idbMetadata || serverMetadata.id) {
    const idbTime = (idbMetadata && idbMetadata.time) || 0;
    const serverTime = serverMetadata.id || 0;
    const metadata =
      serverTime > idbTime ? serverMetadata.state : idbMetadata.state;
    yield loadMetadata(metadata);
    isFinished = metadata.item.finished && !isFix();
    const eanBlob = yield getEanBlob(getPrintOptions(metadata).ean);
    yield put(actionItem.setEanBlob(eanBlob));
  }

  if (
    serverMetadata.id &&
    serverMetadata.state.item.products &&
    serverMetadata.state.item.products.length > 0 &&
    serverMetadata.state.library.products &&
    serverMetadata.state.library.products.length > 0
  ) {
    const state = yield select();
    const pageIndex = getNavigationIndex(state);
    const productId = serverMetadata.state.item.products[pageIndex];
    yield put(
      actionItem.setSelectedProduct(
        serverMetadata.state.library.products.find(({ id }) => id === productId)
      )
    );
  }

  // Mudanca de fetch status
  yield put(actionUI.updateFetchStatus(true));

  if (isFinished) {
    const notUploaded = getNotUploadedAndUsed(serverMetadata.state);

    if (notUploaded.length > 0) {
      yield put(
        actionUI.updatePopup(
          POPUP_ID_SENDING_IMAGES,
          true,
          popupMessage.SENDING_IMAGES()
        )
      );
      yield put(actionGeneral.sagaSubmitOrder(null, true));
    } else {
      const msgLoadFinished = {
        title: `Suas imagens foram enviadas com sucesso!`,
        content: [`Envio concluído!`, `Agora você já pode fechar a página.`]
      };
      yield put(
        actionUI.updatePopup(
          POPUP_ID_SENDING_IMAGES_COMPLETE,
          true,
          msgLoadFinished
        )
      );
    }
  } else {
    if (screenSizeError) {
      yield put(actionUI.updatePopup(POPUP_SCREEN_SIZE_ERROR, true, ''));
    } else {
      if (idbMetadata || serverMetadata.id) {
        yield put(actionUI.updatePopup(POPUP_ID_NONE, false, ''));
      } else {
        yield identify();
      }
    }
  }

  // Get Images from Server
  yield fetchImages(db, itemID);
}

const formatPageName = page =>
  typeof page === 'string' || page > 9 ? page : `0${page}`;

function* cleanImagesNotUploaded(imgNotStored) {
  const state = yield select();
  const imgToRemove = imgNotStored || getNotUploaded(state);
  const prodType = getProductType(state);
  const { pages, covers } = getAllBookPagesData(state);
  const framesToRemove = [];
  const popUpInfo = { name: [], page: [] };

  R.forEach(
    page =>
      page.frames.length &&
      R.forEach(
        frame =>
          imgToRemove.includes(frame.image.id) &&
          framesToRemove.push({
            frameId: frame.id,
            pageId: page.id,
            label: page.label || page.index
          }),
        page.frames
      ),
    [...R.values(covers), ...pages]
  );

  R.forEach(id => {
    const {
      item,
      library: { image, source, metadata, page, frame }
    } = state;

    R.forEach(f => {
      delete frame[f.frameId];

      page[f.pageId].frames = R.filter(
        frame => frame !== f.frameId,
        page[f.pageId].frames
      );

      !popUpInfo.page.includes(f.label) && popUpInfo.page.push(f.label);
    }, framesToRemove);

    delete source[image[id].source.thumb];
    delete source[image[id].source.image];
    popUpInfo.name.push(metadata[image[id].metadata].name);
    delete metadata[image[id].metadata];
    delete image[id];
    item.image = R.filter(i => i !== id, item.image);
  }, imgToRemove);

  yield updateToolbarPhoto(0, imgToRemove.length);
  yield put(actionItem.setPageIndex(state.item.navigation.index));

  let pagesMsg = '',
    namesMsg = '';
  popUpInfo.name.forEach((name, i) =>
    i === popUpInfo.name.length - 1
      ? (namesMsg += `${name}`)
      : i === popUpInfo.name.length - 2
      ? (namesMsg += `${name} e `)
      : (namesMsg += `${name}, `)
  );
  popUpInfo.page.forEach((page, i) =>
    i === popUpInfo.page.length - 1
      ? (pagesMsg += `${formatPageName(page)}`)
      : i === popUpInfo.page.length - 2
      ? (pagesMsg += `${formatPageName(page)} e `)
      : (pagesMsg += `${formatPageName(page)}, `)
  );

  if (imgToRemove.length) {
    pagesMsg.length &&
      popupMessage.UNABLE_LOAD_IMAGE.content.unshift(
        `Por isso, algumas janelas de imagem ficaram em branco n${getGeneroProduto(
          prodType,
          true
        )} ${getTipoQuantidadePadrao()}: ${pagesMsg}`
      );
    namesMsg.length &&
      popupMessage.UNABLE_LOAD_IMAGE.content.unshift(
        `As imagens: ${namesMsg}, não puderam ser carregadas.`
      );
    yield put(
      actionUI.updatePopup(
        POPUP_ID_UNABLE_LOAD_IMAGE,
        true,
        popupMessage.UNABLE_LOAD_IMAGE
      )
    );
  }
}

function* loadMetadata(state) {
  const { item, ui, library } = state;
  if (library.source) {
    removeBlobUrls(library.source);
  }
  yield put(actionGeneral.fetchFromIDB(item, ui, library));
  yield put(actionUI.updateTutorialStatus(''));
}

const removeBlobUrls = source => {
  const keys = Object.keys(source);
  for (const key of keys) {
    source[key].blobUrl = '';
  }
};

function* getIDB() {
  return yield openDB('prin-app', 1);
}

function* idbUpdateImage(img, id, add = true) {
  const db = yield getIDB();
  try {
    if (add) {
      yield db.add('images', { id: `${id}-${img.id}`, img });
    } else {
      yield db.delete('images', `${id}-${img}`);
    }
  } catch (error) {
    // console.error(error);
  }
}

export function* idbUpdateMetadata() {
  const state = yield select();
  const id = getItemId(state);
  const db = yield getIDB();

  try {
    yield db.put('metadata', { id, time: new Date().getTime(), state });
  } catch (error) {
    // console.error(error);
  }
}

const getImgDimensions = (formats, bleed, print, haveCover) => {
  const { page, cover } = formats;
  const dimensions = {
    width: cover.width || page.width,
    height: cover.height || page.height,
    add: cover.debru || bleed
  };
  const { width, height, add } = dimensions;
  return {
    width: print ? width + (haveCover ? add : add * 2) : MAX_WIDTH_REDUCED,
    height: print ? height + add * 2 : MAX_HEIGHT_REDUCED
  };
};

function* addImageFromDB({ id, source, file }) {
  const state = yield select();
  const notUploaded = getNotUploaded(state);
  const print = isPrint(state);

  if (notUploaded.includes(id)) {
    const objectURL = URL.createObjectURL(file);
    const { name, type } = file;
    // Update in server
    yield put(
      actionGeneral.sagaStoreServerImage({ id, name, type, source, objectURL })
    );
  }

  if (id) {
    const formats = yield getFormats(state);
    const haveCover = bookHaveCover(state);
    const { bleed } = getPrintOptions(state);
    const { width, height } = getImgDimensions(
      formats,
      bleed,
      print,
      haveCover
    );

    const imageUrl = yield getImgData(
        file,
        imgOptions(width, height),
        null,
        null,
        print,
        true
      ),
      thumbUrl = yield getImgData(file, thumbOptions, null, null, print, true);

    yield put(actionGeneral.updateBlobURL(source.thumb, thumbUrl));
    yield put(actionGeneral.updateBlobURL(source.image, imageUrl));
  }
}

function* postMetadataLoop({ payload: { id } }) {
  const minutes = 2;
  while (true) {
    yield delay(1000 * 60 * minutes);
    yield put(actionGeneral.sagaPostMetadata(id, 5));
  }
}

function* postImageLoop() {
  while (true) {
    const state = yield select();
    const itemId = getItemId(state);
    const notUploaded = getNotUploaded(state);
    const {
      serverImages: { uploading, imagesToUpload }
    } = state;

    let delayTimeSec = 3;

    const toUpload = notUploaded.filter(id => !uploading.includes(id));
    if (
      toUpload.length &&
      uploading.length < 3 &&
      imagesToUpload[R.head(toUpload)]
    ) {
      yield put(
        actionGeneral.sagaPostImage(itemId, imagesToUpload[R.head(toUpload)])
      );
      delayTimeSec = 6;
    }
    yield delay(1000 * delayTimeSec);
  }
}

function* addFailUploadImage(id) {
  yield put(actionGeneral.sagaAddFailUploadImage(id));
}

function* postImage({ payload: { id, img } }) {
  yield put(actionGeneral.sagaAddServerImageUploading(img.id));

  const { name, type, objectURL } = img;
  const file = yield fetch(objectURL)
    .then(r => r.blob())
    .then(blob => new File([blob], name, { type }));
  const postUrl = `/api/items/${id}/file/${img.id}`;
  let err = false;
  yield postItem(postUrl, file, {
    'Content-Type': type,
    Accept: type
  }).catch(() => {
    err = true;
  });

  if (err) {
    yield addFailUploadImage(img.id);
    try {
      yield resetServerImage(id, img.id);
    } catch {
      if (process.env.NODE_ENV === 'development') {
        console.log('Falha ao resetar imagem do servidor');
      }
    }
  } else {
    window.URL.revokeObjectURL(objectURL);
    yield uptadeImageToUpload(img.id, id);
    yield put(actionGeneral.setUploadImage(img.id, true));
    yield idbUpdateMetadata();
    yield checkImageUploadDone();
  }

  yield put(actionGeneral.sagaRemoveServerImageUploading(img.id));
}

function* checkImageUploadDone() {
  const state = yield select();
  const finished = isFinished(state);
  const notUploaded = getNotUploaded(state).length;
  if (finished && !notUploaded) {
    yield put(actionItem.setImagesUploaded(true));
  }
}

function* updateImagesToUpload(id = false) {
  const state = yield select();
  const itemId = getItemId(state);
  const {
    library: { image },
    serverImages: { uploading, imagesToUpload }
  } = state;
  if (id) {
    if (R.includes(id, R.keys(imagesToUpload)) && !R.includes(id, uploading)) {
      yield uptadeImageToUpload(id, itemId);
    }
  } else {
    const notInBook = R.keys(R.filter(i => !i.used, image));
    const toRemove = R.filter(
      ({ id }) => R.includes(id, notInBook) && !R.includes(id, uploading),
      imagesToUpload
    );
    yield all(R.map(({ id }) => uptadeImageToUpload(id, itemId), toRemove));
  }
}

function* uptadeImageToUpload(id, itemId) {
  yield put(actionGeneral.sagaRemoveStoredServerImage(id));
  yield idbUpdateImage(id, itemId, false);
}

// eslint-disable-next-line
function* submitOrder({ payload: { page, finished } }) {
  postLog('Finalizar produto - Início');
  const state = yield select();
  const itemId = getItemId(state);
  const prodType = getProductType(state);
  const notUploaded = notUploadImage.includes(prodType)
    ? []
    : getNotUploadedAndUsed(state);

  yield put(actionGeneral.sagaPostMetadata(itemId));
  yield put(actionItem.setFinished());
  yield put(actionGeneral.deleteAllImages());
  yield updateImagesToUpload();
  yield idbUpdateMetadata();

  if (prodType !== 'dog-collar') {
    yield put(
      actionUI.updatePopup(
        POPUP_ID_SENDING_IMAGES,
        true,
        popupMessage.SENDING_IMAGES()
      )
    );
  }

  if (notUploaded.length) {
    yield takeLatest(SET_IMAGES_UPLOADED, updateMetadataBeforeFinish);
    if (!finished) {
      yield handleSubmitSuccess('', page);
    }
  } else {
    if (!finished) {
      postLog('Finalizar produto - Enviando imagens');
      yield put(actionUI.updatePopup(POPUP_ID_SENDING_METADATA, true, ''));
      yield updateMetadataBeforeFinish('_self');
    }
  }
}

function* updateMetadataBeforeFinish(type) {
  const state = yield select();
  const { item, ui, library } = state;
  const postUrl = `/api/items/${item.id}/metadata`;
  const metadata = { id: new Date().getTime(), state: { item, ui, library } };
  let errMetadata = false;

  // eslint-disable-next-line
  yield postItem(postUrl, metadata).catch(() => (errMetadata = true));

  if (!errMetadata) {
    yield put(actionItem.setMetadatasUploaded());
    if (type === '_self') {
      yield handleSubmitSuccess(type);
    }
  } else {
    yield updateMetadataBeforeFinish();
  }
}

function* handleSubmitSuccess(type, page) {
  const state = yield select();
  const urls = getUrls(state);
  const page_count = getSetNumber(state);
  const cover_is_scodix = getItemIs3D(state);
  const data = { cover_is_scodix, page_count };

  let errPost = false;

  // eslint-disable-next-line
  yield postItem(urls.post, data).catch(res => (errPost = res));

  if (errPost) {
    postLog('Finalizar produto - Falha ao salvar dados');
    yield delay(1000 * 10);
    yield handleSubmitSuccess(type, page);
  } else {
    postLog('Finalizar produto - Sucesso');
    if (page) {
      page.location.href = urls.cart;
    } else {
      window.open(urls.cart, type);
    }
  }
}

// function* handleSubmitError() {
//   yield put(
//     actionUI.updatePopup(
//       POPUP_ID_ERROR_LOAD,
//       true,
//       'Não foi possível enviar seu produto'
//     )
//   );
// }

function* getFileData(file, id, index, { width, height }, originalDimensions) {
  const source = yield getImage(file, id, width, height);
  return {
    id,
    index,
    staged: true,
    source,
    used: 0,
    uploaded: false,
    metadata: {
      id: cuid(),
      name: file.name,
      lastModified: file.lastModified,
      size: file.size,
      type: file.type,
      originalDimensions
    }
  };
}

const imgOptions = (width, height) => {
  const options = {
    canvas: true,
    orientation: true,
    pixelRatio: window.devicePixelRatio,
    maxWidth: width,
    maxHeight: height
  };
  return options;
};

const thumbOptions = {
  maxWidth: MAX_WIDTH,
  maxHeight: MAX_HEIGHT,
  pixelRatio: window.devicePixelRatio,
  downsamplingRatio: 0,
  canvas: true,
  orientation: true
};

const getImgData = (file, options, type, image, print = false, isUrl = false) =>
  new Promise((resolve, reject) => {
    const loadQuery = loadImage(
      file,
      img => {
        try {
          const { width, height } = img;
          if (!print) {
            const ctx = img.getContext('2d');
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = '#fff';
            ctx.fillRect(0, 0, width, height);
          }

          img.toBlob(
            blob => {
              const url = URL.createObjectURL(blob);
              resolve(
                isUrl
                  ? url
                  : { id: cuid(), blobUrl: url, type, width, height, image }
              );
            },
            print ? file.type : 'image/jpeg',
            print ? 1 : 0.8
          );
        } catch (e) {
          if (process.env.NODE_ENV === 'development') {
            console.log('getImgData error: ', e, file, img);
          }
        }
      },
      options
    );

    loadQuery.onError = reject;
  });

function* getImage(file, imgData, width, height) {
  const thumb = yield getImgData(file, thumbOptions, 'thumb', imgData);
  const image = yield getImgData(
    file,
    imgOptions(width, height),
    'image',
    imgData
  );

  return { thumb, image };
}

const isImageAlreadyUploaded = (file, metadatas) => {
  let found = false;
  metadatas.forEach(metadata => {
    if (
      file.name === metadata.name &&
      file.lastModified === metadata.lastModified &&
      file.size === metadata.size &&
      file.type === metadata.type
    ) {
      found = true;
    }
  });
  return found;
};

const isImageStaged = (file, metadatas, images) => {
  let imageId;
  metadatas.forEach(metadata => {
    if (
      file.name === metadata.name &&
      file.lastModified === metadata.lastModified &&
      file.size === metadata.size &&
      file.type === metadata.type
    ) {
      imageId = metadata.id;
    }
  });
  const { staged, id } = R.head(
    R.filter(R.propEq('metadata', imageId), images)
  );
  return {
    staged,
    id
  };
};

function* loadImageFiles({ payload: { files } }) {
  yield put(actionUI.updateSelectedNav(NAV_NONE_SELECTED));

  const state = yield select();
  const IDBStatus = getIDBStatus(state);
  const imgDataLength = R.length(R.values(state.library.image));
  const metadatas = R.values(state.library.metadata);
  const images = R.values(state.library.image);
  const { bleed } = getPrintOptions(state);
  const formats = getFormats(state);
  const haveCover = bookHaveCover(state);
  const print = isPrint(state);
  const filesRAW = Array.from(files);
  const totalImagesSize = getTotalImagesSize(state);
  const itemId = getItemId(state);
  const defaultMaxUserDiskSpace = getMaxUserDiskSpace(state);
  const defaultMaxImageLength = getMaxImageCount(state);
  const itemStatus = yield fetchItemStatus(itemId);

  const currentMaxUserDiskSpace =
    itemStatus != null &&
    itemStatus.data != null &&
    itemStatus.data.maxUserDiskSpace
      ? Number(itemStatus.data.maxUserDiskSpace)
      : null;

  const currentImageCount =
    itemStatus != null &&
    itemStatus.data != null &&
    itemStatus.data.maxUserNumFiles != null
      ? Number(itemStatus.data.maxUserNumFiles)
      : 0;

  let totalAddingImg = totalImagesSize;

  const maxUserDiskSpace = currentMaxUserDiskSpace
    ? currentMaxUserDiskSpace
    : defaultMaxUserDiskSpace;

  if (filesRAW.length > defaultMaxImageLength) {
    yield put(
      actionUI.updatePopup(
        POPUP_ID_MAX_IMAGE_COUNT,
        true,
        popupMessage.POPUP_ID_MAX_IMAGE_COUNT()
      )
    );

    return;
  }

  for (let i = 0; i < filesRAW.length; i++) {
    const file = filesRAW[i];
    const fileType = String(file.type).toLowerCase();

    if (!fileType.startsWith('image/') || fileType.includes('heic')) {
      continue;
    }

    if (currentImageCount + i + 1 >= defaultMaxImageLength) {
      yield put(
        actionUI.updatePopup(
          POPUP_ID_MAX_IMAGE_COUNT,
          true,
          popupMessage.POPUP_ID_MAX_IMAGE_COUNT()
        )
      );

      return;
    }

    const objectURL = window.URL.createObjectURL(file);
    const originalDimensions = {};
    if (file.size + totalAddingImg >= maxUserDiskSpace) {
      yield put(
        actionUI.updatePopup(
          POPUP_ID_MAX_USER_SPACE,
          true,
          popupMessage.POPUP_MAX_USER_SPACE()
        )
      );

      return;
    }

    totalAddingImg += file.size;

    const img = new Image();
    img.onload = function() {
      originalDimensions.width = this.width;
      originalDimensions.height = this.height;
    };
    img.src = objectURL;

    if (
      imgDataLength === 0 ||
      (imgDataLength > 0 && !isImageAlreadyUploaded(file, metadatas))
    ) {
      const dataId = cuid();
      const dataIndex = imgDataLength + (i + 1);

      const fileInfo = yield getFileData(
        file,
        dataId,
        dataIndex,
        getImgDimensions(formats, bleed, print, haveCover),
        originalDimensions
      );

      const { entities, result } = normalizeImg([fileInfo]);
      yield put(actionItem.addImages(result, entities));
      yield storeImage(file, dataId, entities.image[dataId].source, objectURL);

      // Update in IDB
      if (IDBStatus) {
        yield idbUpdateMetadata();
      }
    } else {
      const image = isImageStaged(file, metadatas, images);
      if (image.staged) {
        // console.log('Imagem já adicionada');
      } else {
        yield put(actionGeneral.setStageImage(image.id, true));
        yield put(actionUI.updateThumbExpand());
      }
    }
  }
}

function* addThemeImages({ payload: { themeImages } }) {
  const state = yield select();
  const IDBStatus = getIDBStatus(state);
  const imgDataLength = R.length(R.values(state.library.image));
  const productType = getProductType(state);

  for (let i = 0; i < themeImages.length; i++) {
    const dataId = cuid();
    const dataIndex = imgDataLength + (i + 1);

    const imageUrl =
      productType === 'mask'
        ? themeImages[i].pdf.image
        : themeImages[i].src.image;
    const imageWidth =
      productType === 'mask'
        ? themeImages[i].pdf.width
        : themeImages[i].src.width;
    const imageHeight =
      productType === 'mask'
        ? themeImages[i].pdf.height
        : themeImages[i].src.height;
    const imageSize =
      productType === 'mask'
        ? themeImages[i].pdf.size
        : themeImages[i].src.size;

    const fileInfo = {
      id: dataId,
      index: dataIndex,
      staged: true,
      isTheme: true,
      source: {
        image: {
          blobUrl: imageUrl,
          id: cuid(),
          image: dataId,
          type: 'image',
          height: imageHeight,
          width: imageWidth
        },
        thumb: {
          blobUrl: themeImages[i].thumb.image,
          id: cuid(),
          image: dataId,
          type: 'thumb',
          height: themeImages[i].thumb.height,
          width: themeImages[i].thumb.width
        }
      },
      used: 0,
      uploaded: true,
      metadata: {
        id: cuid(),
        name: themeImages[i].name,
        isTheme: true,
        imageUrl: imageUrl,
        thumbUrl: themeImages[i].thumb.image,
        lastModified: new Date().getTime(),
        size: imageSize,
        type: 'theme',
        originalDimensions: {
          height: imageHeight,
          width: imageWidth
        }
      }
    };

    const { entities, result } = normalizeImg([fileInfo]);

    yield put(actionItem.addImages(result, entities));

    // Update in IDB
    if (IDBStatus) yield idbUpdateMetadata();
  }
}

function* storeImage(file, dataId, source, objectURL) {
  const state = yield select();
  const IDBStatus = getIDBStatus(state);
  const itemId = getItemId(state);

  const imgServer = {
    id: dataId,
    source,
    timestamp: new Date().getTime(),
    name: file.name,
    type: file.type
  };

  yield put(actionGeneral.sagaStoreServerImage({ ...imgServer, objectURL }));

  if (IDBStatus) {
    yield idbUpdateImage({ ...imgServer, file }, itemId);
  }
}

function* richTextUpdate({
  payload: { pageId, richText, richTextState, isFirst, spineFill, x, y, l, a }
}) {
  yield delay(1);
  const state = yield select();
  const IDBStatus = getIDBStatus(state);
  const prodType = getProductType(state);

  const navigatorData = getAllBookPagesData(state);
  const spineModified = isSpineModified(state);
  const textOptions = getTextOptions(state);

  if (
    pageId === 'spine' &&
    !spineModified &&
    R.length(richTextState) &&
    !spineFill
  ) {
    yield put(actionItem.updateIsSpineModified(true));
  }

  if (textOptions.color === 'default') {
    textOptions.color =
      prodType === 'dog-collar' ? 'rgb(245,245,245)' : 'rgb(0, 0, 0)';
    textOptions.size =
      prodType === 'dog-collar'
        ? calculaSizeFamily(textOptions.family, textOptions)
        : tamanhoDefaulltTexto(prodType);
    textOptions.colorArray =
      prodType === 'dog-collar'
        ? textOptions.colorArrayDogCollar
        : textOptions.colorArray;
    textOptions.familyArray = getFamilyArray(prodType, textOptions);
    textOptions.textStyle.align = prodType === 'dog-collar' ? CENTER : LEFT;
  }

  textOptions.sizeArray =
    prodType === 'dog-collar'
      ? calculaSizeArrayFamily('dog-collar', textOptions)
      : textOptions.sizeArray;

  const text = {
    id: richText,
    richTextState,
    textOptions: {
      color: isFirst ? undefined : textOptions.color,
      family: isFirst ? undefined : textOptions.family,
      size: isFirst ? undefined : textOptions.size,
      sizeArray: isFirst ? undefined : textOptions.sizeArray,
      align: isFirst ? undefined : textOptions.textStyle.align,
      bold: isFirst ? undefined : textOptions.textStyle.bold,
      italic: isFirst ? undefined : textOptions.textStyle.italic,
      underline: isFirst ? undefined : textOptions.textStyle.underline,
      x: x,
      y: y,
      l: l,
      a: a
    }
  };

  const dropPage = R.head(
    R.filter(x => R.equals(R.prop('id', x), pageId), [
      ...R.prop('pages', navigatorData),
      ...R.values(R.prop('covers', navigatorData))
    ])
  );

  const foundKeyIndex = R.findIndex(R.propEq('id', richText))(
    R.prop('texts', dropPage)
  );

  const dropPageWithTexts = {
    ...dropPage,
    texts: dropPage.texts
      .map((textData, index) => (index === foundKeyIndex ? text : textData))
      .concat(foundKeyIndex === -1 ? [text] : [])
  };

  const { result, entities } = normalizePage([dropPageWithTexts]);
  yield put(actionItem.addPages(result, entities));

  if (prodType === 'dog-collar') {
    const menorPaginaVazia = getFirstEmptyTextPage(state);
    if (
      menorPaginaVazia != null &&
      menorPaginaVazia !== pageId &&
      richTextState.length <= 0
    ) {
      yield setPageIndex({ payload: { index: menorPaginaVazia.id } });
    }
  }

  if (
    pageId === 'cover' &&
    !spineModified &&
    richText === R.path(['texts', 0, 'id'], dropPage)
  ) {
    const spineText = R.path(['covers', 'spine', 'texts', 0], navigatorData);
    if (spineText) {
      yield put(
        actionGeneral.sagaSetRichText(
          spineText.id,
          'spine',
          richTextState,
          false,
          true
        )
      );
    }
  }

  if (IDBStatus) {
    yield idbUpdateMetadata();
  }

  if (
    !richTextState &&
    richTextState.length === 0 &&
    !isFirst &&
    richText &&
    dropPage.texts
  ) {
    const textoAnterior = dropPage.texts.find(({ id }) => id === richText);
    if (textoAnterior && textoAnterior.richTextState.length > 0) {
      yield put(actionGeneral.sagaSwapPoster('LEFT'));
    }
  } else if (
    dropImageEmptyPage(prodType) &&
    richTextState &&
    richTextState.length > 0 &&
    !isFirst &&
    richText &&
    dropPage.texts
  ) {
    const textoAnterior = dropPage.texts.find(({ id }) => id === richText);
    if (!textoAnterior || textoAnterior.richTextState.length === 0) {
      yield movePage({ payload: { side: 'SWAP' } });
    }
  }
}

function* thumbDropImage({
  payload: { thumbId, shapeId, pageIndex, pageId, fillPages, shuffleLayout }
}) {
  const state = yield select();
  const IDBStatus = getIDBStatus(state);

  const id = cuid();
  const { image, width: imgWidth, height: imgHeight } = R.path(
    ['source', 'image'],
    R.find(R.pathEq(['source', 'thumb', 'id'], thumbId))(getImagesData(state))
  );

  const frame = { id, image, shape: shapeId };

  const pageLength = getBookNavigatorVisiblePagesLength(state);
  const haveCover = bookHaveCover(state);
  const productType = getProductType(state);

  let bookNavigatorData;

  if (shuffleLayout || fillPages) {
    bookNavigatorData = getAllBookPagesData(state);
    if (fillPages) {
      let idx = Math.floor(pageIndex / pageLength);
      idx = thumbDropImageIdx.includes(productType) ? idx - 1 : idx;
      if (productType !== 'deck-cards')
        yield put(
          actionItem.setPageIndex(
            haveCover && !R.prop(pageId, R.prop('covers', bookNavigatorData))
              ? idx + 1
              : idx
          )
        );
    }
  } else {
    bookNavigatorData = getBookNavigatorData(state);
  }

  let dropPage =
    R.head(
      R.filter(R.propEq('id', pageId), R.prop('pages', bookNavigatorData))
    ) || R.prop(pageId, R.prop('covers', bookNavigatorData));

  if (
    dropImageEmptySimplePage(productType) &&
    dropPage != null &&
    dropPage.frames.length === 0 &&
    dropPage.style != null &&
    dropPage.style.shape != null &&
    dropPage.style.shape.length <= 1
  ) {
    const proximaPagina = getFirstEmptyPage(state);
    if (proximaPagina != null) {
      dropPage = { ...proximaPagina };
      yield setPageIndex({ payload: { index: proximaPagina.id } });
    }
  }

  if (dropImageEmptyPage(productType) && fillPages && pageId != null) {
    yield setPageIndex({ payload: { index: pageId } });
  }

  const shape = R.find(R.propEq('id', shapeId))(
    R.path(['style', 'shape'], dropPage)
  );

  const foundUsedFrame = R.prop(
    'id',
    R.find(R.pathEq(['shape', 'id'], shapeId))(R.prop('frames', dropPage))
  );

  const foundUsedImage = R.prop(
    'image',
    R.find(R.pathEq(['shape', 'id'], shapeId))(R.prop('frames', dropPage))
  );

  const frameToBeRemoved = R.find(R.pathEq(['image', 'id'], image))(
    R.prop('frames', dropPage)
  );

  const { bleed } = getPrintOptions(state);
  [frame.posX, frame.posY] = calcularPosicaoinicialImagem(
    shape,
    imgWidth,
    imgHeight,
    bleed
  );

  const dropPageWithFrame = {
    ...dropPage,
    frames: dropPage.frames
      .filter(
        frameData =>
          frameData.id !==
          (shuffleLayout ? frameToBeRemoved.id : foundUsedFrame)
      )
      .concat([frame])
  };

  const { result, entities } = normalizePage([dropPageWithFrame]);

  yield put(actionItem.addPages(result, entities));

  if (!shuffleLayout) {
    yield put(actionGeneral.updateUsedImage(image, 'INCREASE'));
  }

  if (foundUsedFrame && !shuffleLayout) {
    yield put(actionGeneral.removeFrame(foundUsedFrame));
    yield put(actionGeneral.updateUsedImage(foundUsedImage.id, 'DECREASE'));
  }

  if (shuffleLayout && frameToBeRemoved) {
    yield put(actionGeneral.removeFrame(frameToBeRemoved.id));
  }

  if (dropImageEmptyPage(productType) && !fillPages) {
    yield movePage({ payload: { side: null } });
  }

  if (IDBStatus) {
    yield idbUpdateMetadata();
  }
}

const bookContainsImage = pages => {
  let frames = 0;
  pages.map(page => (frames += page.frames.length));
  return frames === 0 ? false : true;
};

function* updateToolbarPhoto(inc = 0, dec = 0) {
  const state = yield select();
  const {
    maxThumbs,
    thumbsLength,
    prevThumbLenth,
    thumbListLeft,
    thumbListRight,
    thumbsListExpanded
  } = getToolbarPhoto(state);

  yield put(
    actionUI.updateToolbarPhoto(
      maxThumbs,
      thumbsLength - dec,
      prevThumbLenth - dec,
      {
        start: thumbListLeft.start - thumbListLeft.start + inc,
        end: thumbListLeft.end - thumbListLeft.start + inc
      },
      {
        start: thumbListRight.start - thumbListLeft.start + inc,
        end: thumbListRight.end - thumbListLeft.start + inc
      },
      {
        start: thumbsListExpanded.start,
        end: thumbsListExpanded.end - dec
      }
    )
  );
}

function* insertPages() {
  const state = yield select();
  const IDBStatus = getIDBStatus(state);
  let thumbData = getAllImagesThumb(state);
  const thumbsLength = R.length(thumbData);
  let { pages, covers } = getAllBookPagesData(state);
  const shapesInBook = getShapesInBook(state);
  const haveCover = bookHaveCover(state);
  const prodType = getProductType(state);

  if (prodType === 'deck-cards') pages = getDeckCardsPagesData(state);

  if (haveCover) {
    pages.unshift(R.prop('back_cover', covers));
    pages.unshift(R.prop('cover', covers));
  }

  if (thumbsLength > 0) {
    yield updateToolbarPhoto(0);
    let bookShapesData = [];
    const dataToInsert = [];

    pages.forEach(({ style, frames, id, index }) =>
      style.shape.forEach(shape =>
        bookShapesData.push({
          styleId: `${style.id}`,
          shapeId: shape.id,
          pageIndex: index,
          pageId: id,
          frameId: R.length(frames)
            ? R.find(R.pathEq(['shape', 'id'], R.prop('id', shape)))(frames)
              ? R.prop(
                  'id',
                  R.find(R.pathEq(['shape', 'id'], R.prop('id', shape)))(frames)
                )
              : undefined
            : undefined
        })
      )
    );

    if (bookContainsImage(pages)) {
      const frames = getFrames(state);

      let framesToRemove = [];
      let thumbsToRemove = [];

      R.values(frames).forEach(frame => {
        framesToRemove.push(frame.id);
        thumbsToRemove.push(frame.image);
      });

      bookShapesData = bookShapesData.filter(
        page => !framesToRemove.includes(page.frameId)
      );

      thumbData = thumbData.filter(
        thumb => !thumbsToRemove.includes(thumb.image)
      );
    }

    thumbData.forEach(
      ({ id }, index) =>
        index < bookShapesData.length &&
        dataToInsert.push({
          ...bookShapesData[index],
          thumbId: `${id}`
        })
    );

    if (prodType === 'deck-cards') yield addImageDeckCards(pages, dataToInsert);
    else yield all(dataToInsert.map((data, index) => addImageAux(data, index)));

    if (IDBStatus) {
      yield idbUpdateMetadata();
    }

    if (shapesInBook > thumbsLength) {
      yield put(actionUI.setStateLegenda('POPUP'));
      yield put(
        actionUI.updatePopup(
          POPUP_ID_FILL_PAGES_PHOTOS_NEEDED,
          true,
          popupMessage.FILL_PAGES_PHOTOS_NEEDED()
        )
      );
    }
    if (thumbsLength > shapesInBook) {
      yield put(actionUI.setStateLegenda('POPUP'));
      yield put(
        actionUI.updatePopup(
          POPUP_ID_FILL_PAGES_PAGES_NEEDED,
          true,
          popupMessage.FILL_PAGES_PAGES_NEEDED()
        )
      );
    }
  }
}

function* addImageDeckCards(pages, dataToInsert) {
  let trocou = false;
  for (let index = 0; index < dataToInsert.length; index++) {
    if (!trocou && index >= pages[0].style.shape.length) {
      yield put(actionItem.toggleModoSobFivela());
      trocou = true;
    }
    yield addImageAux(dataToInsert[index], 2);
  }
}

function* addImageAux({ thumbId, shapeId, pageIndex, pageId, styleId }, index) {
  yield delay(index * 500);

  const state = yield select();
  const {
    maxThumbs,
    thumbsLength,
    thumbListRight: { end }
  } = getToolbarPhoto(state);
  const thumbData = getAllImagesThumb(state);
  const thumbIndex = R.findIndex(R.propEq('id', thumbId))(thumbData);
  const acc =
    end + maxThumbs * 2 > thumbsLength - 1
      ? thumbsLength - maxThumbs * 2
      : end + 1;
  if (thumbIndex > end) {
    yield updateToolbarPhoto(acc);
  }

  yield put(
    actionGeneral.sagaThumbDropImage(
      thumbId,
      shapeId,
      pageIndex,
      pageId,
      styleId,
      true
    )
  );
}

function* removePages() {
  const state = yield select();
  const IDBStatus = getIDBStatus(state);
  const frames = state.library.frame;
  const keys = Object.keys(frames);

  for (let i = 0; i < keys.length; i++) {
    yield put(actionGeneral.updateUsedImage(frames[keys[i]].image, 'REMOVE'));
  }

  yield put(actionItem.removePages());
  yield put(actionGeneral.removePages());

  // eslint-disable-next-line
  const pages = getAllBookPagesData(state).pages;
  if (IDBStatus) {
    yield idbUpdateMetadata();
  }
}

function* removeTexts() {
  const state = yield select();
  const IDBStatus = getIDBStatus(state);

  const pages = getAllBookPagesData(state).pages;
  yield all(
    pages.map(
      ({ texts, id }) =>
        texts.length > 0 && put(actionGeneral.removeTextsFromPage(id))
    )
  );

  if (IDBStatus) {
    yield idbUpdateMetadata();
  }
}

const findLastPageIndex = (prodType, allPages) => {
  let index = allPages.length;
  if (index && index > 0) {
    if (prodType === 'mask') index = index - 1;
    else {
      index = index - 2;
    }
  }
  return index;
};

function* overviewRemovePages({ payload: { pages, remove } }) {
  const state = yield select();
  const IDBStatus = getIDBStatus(state);
  const allPages = getAllBookPagesData(state).pages;
  const { pages: pagesToRemove } = getOverviewRemovePages(state);
  const nextSet = getNextSetId(state);
  const previousSet = getPreviousSetId(state);
  const secondSet = getPageOrdenatedSetArr(state)[1].id;
  const setDif = getSetDif(state);
  const prodType = getProductType(state);
  const lastPageIndex = findLastPageIndex(prodType, allPages);

  if (secondSet !== nextSet) {
    if (!remove) {
      if (pagesToRemove.length + pages.length !== setDif) {
        yield put(actionItem.setPagesToRemove(pages));
      } else {
        const pageToRemoveHaveContent = [];
        R.forEach(
          page =>
            [...pagesToRemove, ...pages].includes(page.index) &&
            (page.frames.length || checkTextsLength(page)) &&
            pageToRemoveHaveContent.push(page.index),
          allPages
        );
        if (pageToRemoveHaveContent.length > 0) {
          let pagesString = '';
          pageToRemoveHaveContent.forEach((page, i) =>
            i
              ? i === pageToRemoveHaveContent.length - 1
                ? (pagesString += ` e ${page > 9 ? page : `0${page}`}`)
                : (pagesString += `, ${page > 9 ? page : `0${page}`}`)
              : (pagesString += `${page > 9 ? page : `0${page}`}`)
          );
          yield put(actionUI.setStateLegenda('POPUP'));
          yield put(
            actionUI.updatePopup(
              POPUP_ID_REMOVE_PAGES,
              true,
              popupMessage.REMOVE_PAGES(
                `páginas ${pagesString}`,
                pageToRemoveHaveContent
              )
            )
          );
          yield put(actionItem.toggleRemovePages());
        } else {
          yield put(actionItem.setPagesToRemove(pages));
          yield put(actionUI.setStateLegenda('POPUP'));
          yield put(
            actionUI.updatePopup(
              POPUP_ID_CONFIRM_REMOVE_PAGES,
              true,
              popupMessage.CONFIRM_REMOVE_PAGES()
            )
          );
        }
      }
    } else {
      // Remove Frames
      const updatePages = allPages.filter(
        ({ index }) =>
          index >= Math.min(...pagesToRemove) && index < lastPageIndex
      );

      const previousLastPage = allPages[lastPageIndex];

      yield all(
        updatePages.map(
          ({ frames, id, index }) =>
            !!frames.length &&
            overviewRemoveFrames(frames, id, !pagesToRemove.includes(index))
        )
      );

      // Set new pages
      const newPageSet = updatePages
        .filter(({ index }) => !pagesToRemove.includes(index))
        .map(page => {
          const decrease = pagesToRemove.reduce(
            (sum, i) => (page.index > i ? sum + 1 : sum),
            0
          );
          page.id -= decrease;
          page.index -= decrease;
          return page;
        });

      if (checkTextsLength(previousLastPage) && prodType !== 'dog-collar') {
        yield put(actionGeneral.removeTextsFromPage(previousLastPage.id));
      }

      if (R.length(R.prop('frames', previousLastPage))) {
        yield overviewRemoveFrames(
          R.prop('frames', previousLastPage),
          R.prop('id', previousLastPage),
          true
        );
      }

      yield all(newPageSet.map(page => addPage(page)));
      yield put(actionItem.toggleRemovePages());
      yield put(actionItem.updatePageSetId(previousSet));
      yield adjustLastPage(prodType, previousLastPage);

      yield put(actionUI.updatePopup(POPUP_ID_NONE, false, ''));
    }
  }
  if (IDBStatus) {
    yield idbUpdateMetadata();
  }
}

function* adjustLastPage(prodType, { style, frames, texts }) {
  const state = yield select();
  const allPages = getAllBookPagesData(state);
  const lastPageIndex = findLastPageIndex(prodType, allPages);
  if (lastPageIndex && lastPageIndex > 0) {
    const lastpage = R.path(['pages', lastPageIndex], allPages);
    lastpage.style = style;
    lastpage.frames = frames;
    lastpage.texts = texts;
    yield setLayoutAux(lastpage);
  }
}

function* overviewRemoveFrames(frames, pageId, removeImage) {
  yield all(
    frames.map(frame => overviewRemoveFramesAux(frame.id, pageId, removeImage))
  );
}

function* overviewRemoveFramesAux(id, pageId, removeImage) {
  yield put(actionGeneral.sagaDeleteImageFromBook(id, pageId, removeImage));
}

const framesContainsImage = (id, list) =>
  R.reduce(
    (acc, i) => (acc ? R.T() : R.equals(R.prop('image', i), id)),
    false,
    R.values(list)
  );

const firstPageWithFrame = (prodType, id, pages) => {
  const pagesWithFrame = R.filter(({ frames }) => R.length(frames) > 0, pages);
  const pageArr = [];
  pagesWithFrame.forEach(({ frames, index, id: pageId }) =>
    frames.forEach(
      ({ image }) =>
        image.id === id &&
        pageArr.push(typeof pageId === 'string' ? pageId : index)
    )
  );
  const fPage = R.head(pageArr);

  if (prodType !== 'mask')
    return typeof fPage === 'string' ? -1 : parseInt(fPage / 2);
  return typeof fPage === 'string' ? -1 : parseInt(fPage) - 1;
};

function* unstageImage({ payload: { id } }) {
  const state = yield select();
  const frames = getFrames(state);
  const prodType = getProductType(state);
  const allPages = [
    ...R.values(R.prop('covers', getAllBookPagesData(state))),
    ...R.prop('pages', getAllBookPagesData(state))
  ];

  const containsImage = framesContainsImage(id, frames);

  if (containsImage) {
    yield put(actionUI.setStateLegenda('POPUP'));
    yield put(
      actionUI.updatePopup(
        POPUP_ID_IMAGE_USED,
        true,
        popupMessage.IMAGE_USED(firstPageWithFrame(prodType, id, allPages))
      )
    );
  } else {
    yield put(actionGeneral.setStageImage(id, false));
    yield updateImagesToUpload(id);
    yield put(actionUI.updateThumbExpand());
  }
}

function* setLastPageLayout() {
  const state = yield select();
  const productType = getProductType(state);

  if (productType === 'book') {
    const haveCover = bookHaveCover(state);
    const pageSummary = getPageSummary(state);
    const setNumber = getSetNumber(state);
    const allPagesData = getAllBookPagesData(state);

    if (!haveCover && !pageSummary.includes(setNumber - 1)) {
      yield setLayoutAux(R.path(['pages', setNumber], allPagesData));
    }
  }
}

function* increasePageIndex() {
  const state = yield select();
  const IDBStatus = getIDBStatus(state);
  const pageIndex = getNavigationIndex(state);
  const pageLength = getBookNavigatorVisiblePagesLength(state);
  const setNumber = getSetNumber(state);
  const endpapersPageLength = getEndpapersLength(state);
  const coversLength = bookHaveCover(state) ? 2 : 0;
  const prodType = getProductType(state);

  let nextIndex =
    (pageIndex + 1) %
    ((setNumber + endpapersPageLength + coversLength) / pageLength);

  if (prodType === 'invitation' && pageIndex === 1) nextIndex = 0;

  yield put(actionItem.setPageIndex(nextIndex));

  yield setUserProduct(state, nextIndex);

  changeTextOption(state);

  if (IDBStatus) {
    yield idbUpdateMetadata();
  }
}

function* decreasePageIndex() {
  const state = yield select();
  const IDBStatus = getIDBStatus(state);
  const pageIndex = getNavigationIndex(state);
  const setNumber = getSetNumber(state);
  const pageLength = getBookNavigatorVisiblePagesLength(state);
  const endpapersPageLength = getEndpapersLength(state);
  const coversLength = bookHaveCover(state) ? 2 : 0;

  const nextIndex =
    pageIndex - 1 < 0
      ? (setNumber + endpapersPageLength + coversLength) / pageLength - 1
      : pageIndex - 1;

  yield put(actionItem.setPageIndex(nextIndex));

  yield setUserProduct(state, nextIndex);

  changeTextOption(state);

  if (IDBStatus) {
    yield idbUpdateMetadata();
  }
}

function* setUserProduct(state, index) {
  const prodType = getProductType(state);

  if (isCustomProduct(prodType)) {
    const userProducts = getUserProducts(state);
    const products = getCustomProducts(state);

    const product = products.find(({ id }) => id === userProducts[index]);

    if (product) yield put(actionItem.setSelectedProduct(product));
  }
}

function* changeTextOption(state) {
  const prodType = getProductType(state);

  if (prodType === 'dog-collar') {
    const textOptions = getTextOptions(state);
    const textOptionsAtual = getTextOptionsColeira(state);

    if (
      textOptions.color !== textOptionsAtual.color ||
      textOptions.family !== textOptionsAtual.family ||
      textOptions.size !== textOptionsAtual.size ||
      textOptions.align !== textOptionsAtual.align ||
      textOptions.bold !== textOptionsAtual.bold ||
      textOptions.italic !== textOptionsAtual.italic ||
      textOptions.underline !== textOptionsAtual.underline
    ) {
      yield put(
        actionUI.updateTextOptions(ALL, {
          color: textOptionsAtual.color,
          family: textOptionsAtual.family,
          size: textOptionsAtual.size,
          align: textOptionsAtual.align,
          bold: textOptionsAtual.bold,
          italic: textOptionsAtual.italic,
          underline: textOptionsAtual.underline,
          prodType: 'dog-collar'
        })
      );
    }
  }
}

export function* addPage(page, move = true) {
  yield delay(1);
  const { result, entities } = normalizePage([page]);
  yield put(actionItem.addPages(result, entities, move));
}

export function* mapFramesToRemove(frames, mapPageIndexFrame) {
  yield all(frames.map(({ id }) => removeFrame(id, mapPageIndexFrame)));
}

export function* removeFrame(id, frame) {
  const pageIndex =
    typeof frame === 'number'
      ? frame
      : R.prop('pageIndex', R.head(R.filter(R.propEq('frameId', id), frame)));

  yield put(actionGeneral.sagaDeleteImageFromBook(id, pageIndex, true));
}

export function* setPageIndex({ payload: { index } }) {
  const state = yield select();
  const IDBStatus = getIDBStatus(state);
  const setNumber = getSetNumber(state);
  const pageLength = getBookNavigatorVisiblePagesLength(state);
  const endpapersPageLength = getEndpapersLength(state);

  const lastIndex = (setNumber + endpapersPageLength) / pageLength;
  index = lastIndex < index ? index % lastIndex : index;

  yield put(actionItem.setPageIndex(index));
  if (IDBStatus) {
    yield idbUpdateMetadata();
  }
}

function* setFirstPage() {
  const state = yield select();
  const IDBStatus = getIDBStatus(state);
  const haveCover = bookHaveCover(state);
  const pageIndex = getNavigationIndex(state);
  const firstPage = haveCover && !pageIndex ? 1 : 0;
  yield setUserProduct(state, firstPage);
  yield put(actionItem.setPageIndex(firstPage));
  if (IDBStatus) {
    yield idbUpdateMetadata();
  }
}

function* setLastPage({ payload: { last } }) {
  const state = yield select();
  const IDBStatus = getIDBStatus(state);
  const setNumber = getSetNumber(state);
  const pageLength = getBookNavigatorVisiblePagesLength(state);
  const haveCover = bookHaveCover(state);
  const pageIndex = getNavigationIndex(state);
  const endpapersLength = getEndpapersLength(state);
  const coversLength = haveCover ? 2 : 0;
  const lastPage =
    (setNumber + coversLength) / pageLength - (!endpapersLength ? 1 : 0);
  const cover = 0;

  yield setUserProduct(
    state,
    last ? lastPage : haveCover && pageIndex ? cover : lastPage
  );

  yield put(
    actionItem.setPageIndex(
      last ? lastPage : haveCover && pageIndex ? cover : lastPage
    )
  );

  if (IDBStatus) {
    yield idbUpdateMetadata();
  }
}

function* adjustLayoutsFilter() {
  const state = yield select();
  const { side } = getLayoutsFilter(state);
  const { pages } = getBookNavigatorData(state);
  const page = side === 'LEFT' ? R.head(pages) : R.last(pages);

  const {
    style: { id, shape, textarea }
  } = page;

  const shapesNumber = shape.length;
  const text = textarea === undefined ? 'sem' : 'com';

  yield put(actionItem.adjustLayoutsFilter(id, shapesNumber, text));
}

const isSameStyle = (l, previousStyle) =>
  l.cover === previousStyle.cover &&
  l.back_cover === previousStyle.back_cover &&
  l.spine === previousStyle.spine &&
  l.page === previousStyle.page;

const isText = (l, text) =>
  (text === 'com' && l.textarea !== undefined) ||
  (text === 'sem' && l.textarea === undefined);

function* adjustLayoutsFilterPhotoNumber({ payload: { type } }) {
  const state = yield select();
  const { photos, text, layoutSelected } = getLayoutsFilter(state);
  const styles = getProductStyles(state);
  const previousStyle = R.find(R.propEq('id', layoutSelected))(styles);
  const isPhoto = l =>
    l.shape &&
    ((type === 'INCREASE' && l.shape.length > photos) ||
      (type === 'DECREASE' && l.shape.length < photos));
  const filterStyle = l =>
    isPhoto(l) && isSameStyle(l, previousStyle) && isText(l, text);
  const stylesFiltered = R.filter(filterStyle, styles);

  if (stylesFiltered.length) {
    const shapesLength = R.map(s => s.shape.length, stylesFiltered);
    const shapesSorted = R.sort(
      (a, b) => (type === 'INCREASE' ? a - b : b - a),
      shapesLength
    );
    yield put(actionItem.updateLayoutFilterPhotoNumber(R.head(shapesSorted)));
  }
}

// SetLayout after UpdateLayoutFilter
function* updateLayoutFilterAux({ payload: { value } }) {
  const state = yield select();
  const prodType = getProductType(state);

  const { photos, text, layoutSelected, side } = getLayoutsFilter(state);

  const doublePage = R.prop('pages', getBookNavigatorData(state));
  const page = side === 'LEFT' ? R.head(doublePage) : R.last(doublePage);
  const haveCover = bookHaveCover(state);
  const maxPage = getSetNumber(state);
  const isLastPageFlex =
    calcLastPageFlex(prodType) && !haveCover && page.id === maxPage - 1;

  const styles = getProductStyles(state);
  const previousStyle = R.find(R.propEq('id', layoutSelected))(styles);
  const isPhoto = l => l.shape && l.shape.length === photos;
  const isLastPage = l => (isLastPageFlex ? l.lastpage : true);
  const filterStyle = l =>
    isPhoto(l) &&
    isText(l, text) &&
    isSameStyle(l, previousStyle) &&
    isLastPage(l);

  const stylesFiltered = R.filter(filterStyle, styles);
  if (stylesFiltered.length) {
    const layoutId =
      value && value !== 'com' && value !== 'sem'
        ? layoutSelected
        : R.prop('id', R.head(stylesFiltered));
    yield put(actionGeneral.sagaSetLayout(layoutId));
  }
}

function* setLayout({ payload: { layoutId, keepGoing } }) {
  const state = yield select();
  const IDBStatus = getIDBStatus(state);
  const { applyMethod, side } = getLayoutsFilter(state);
  const styleSelected = R.head(
    getProductStyles(state).filter(style => (style.id === layoutId ? 1 : 0))
  );
  const selPhoto = styleSelected.shape.length;
  const selText = styleSelected.textarea ? 'com' : 'sem';
  const haveCover = bookHaveCover(state);

  const doublePage = R.prop('pages', getBookNavigatorData(state));
  const page = side === 'LEFT' ? R.head(doublePage) : R.last(doublePage);
  const allPages = R.prop('pages', getAllBookPagesData(state));

  const setNumber = getSetNumber(state);
  const prodType = getProductType(state);
  const lastPageWarning =
    !haveCover &&
    !R.prop('lastpage', styleSelected) &&
    haveLastPageWarning(prodType);

  const checkPhotos = (pages, side) => maxFrames(pages, side) > selPhoto;
  const checkTexts = (pages, side) =>
    maxTextSize(pages, side) >
    (styleSelected.textarea ? styleSelected.textarea.length : 0);

  switch (applyMethod) {
    case `somente ${getTipoPag()} atual`:
    case 'a todas as cartas verso':
    case 'a todas as cartas frente':
      if (checkPhotos([page]) || checkTexts([page])) {
        yield setLayoutPopup(selPhoto, maxTextSize([page]), [page]);
      } else {
        yield put(actionItem.adjustLayoutsFilter(layoutId, selPhoto, selText));
        page.style = styleSelected;
        yield setLayoutAux(page);
      }
      break;
    case `${getTipoPag()} dupla`:
      if (checkPhotos(doublePage) || checkTexts(doublePage)) {
        yield setLayoutPopup(selPhoto, maxTextSize(doublePage), doublePage);
      } else {
        yield put(actionItem.adjustLayoutsFilter(layoutId, selPhoto, selText));
        yield all(
          doublePage.map(page => {
            if (page.index > 0) {
              page.style = styleSelected;
            }
            return setLayoutAux(page);
          })
        );
      }
      break;
    case 'a todas as páginas da esquerda':
      if (checkPhotos(allPages, 'LEFT') || checkTexts(allPages, 'LEFT')) {
        yield setLayoutPopup(
          selPhoto,
          maxTextSize(allPages, 'LEFT'),
          allPages,
          'LEFT'
        );
      } else {
        if (side === 'LEFT') {
          yield put(
            actionItem.adjustLayoutsFilter(layoutId, selPhoto, selText)
          );
        }
        if (!haveCover && lastPageWarning && !keepGoing) {
          yield showLastPageWarning(styleSelected.id);
        } else {
          yield all(
            // eslint-disable-next-line
            allPages.map(page => {
              if (lastPageWarning && page.index === setNumber) return null;
              if (page.index > 0 && page.index % 2 === 0) {
                page.style = styleSelected;
                return setLayoutAux(page);
              }
            })
          );
        }
      }
      break;
    case 'a todas as páginas da direita':
      if (checkPhotos(allPages, 'RIGHT') || checkTexts(allPages, 'RIGHT')) {
        yield setLayoutPopup(
          selPhoto,
          maxTextSize(allPages, 'RIGHT'),
          allPages,
          'RIGHT'
        );
      } else {
        if (side === 'RIGHT') {
          yield put(
            actionItem.adjustLayoutsFilter(layoutId, selPhoto, selText)
          );
        }
        yield all(
          // eslint-disable-next-line
          allPages.map(page => {
            if (page.index > 0 && page.index % 2 !== 0) {
              page.style = styleSelected;
              return setLayoutAux(page);
            }
          })
        );
      }
      break;
    case applyMethodTodos():
      if (checkPhotos(allPages) || checkTexts(allPages)) {
        yield setLayoutPopup(selPhoto, maxTextSize(allPages), allPages);
      } else {
        yield put(actionItem.adjustLayoutsFilter(layoutId, selPhoto, selText));
        if (!haveCover && lastPageWarning && !keepGoing) {
          yield showLastPageWarning(styleSelected.id);
        } else {
          yield all(
            // eslint-disable-next-line
            allPages.map(page => {
              if (lastPageWarning && page.index === setNumber) return null;
              if (page.index > 0) {
                page.style = styleSelected;
                return setLayoutAux(page);
              }
            })
          );
        }
      }
      break;
    default:
      break;
  }

  if (IDBStatus) {
    yield idbUpdateMetadata();
  }
}

const maxFrames = (pages, side) => {
  let frames = 0;
  pages.forEach(page => {
    if (page.frames.length > frames) {
      if (side === undefined) {
        frames = page.frames.length;
      } else if (side === 'RIGHT' && page.index % 2 !== 0) {
        frames = page.frames.length;
      } else if (side === 'LEFT' && page.index % 2 === 0) {
        frames = page.frames.length;
      }
    }
  });
  return frames;
};

const lengthText = texts => R.length(R.prop('richTextState', R.head(texts)));

const pagesHaveText = (pages, side) => {
  let textnum = 0;
  pages.forEach(({ index, texts }) => {
    if (texts.length > 0) {
      if (side === undefined) {
        textnum += lengthText(texts);
      } else if (side === 'RIGHT' && index % 2 !== 0) {
        textnum += lengthText(texts);
      } else if (side === 'LEFT' && index % 2 === 0) {
        textnum += lengthText(texts);
      }
    }
  });
  return textnum;
};

const maxTextSize = (pages, side) => {
  let textsSizes = pages;
  if (side)
    textsSizes = textsSizes.filter(
      ({ index }) =>
        (side === 'RIGHT' && index % 2 !== 0) ||
        (side === 'LEFT' && index % 2 === 0)
    );
  textsSizes = textsSizes.map(({ texts }) =>
    texts.reduce((sum, t) => (t.richTextState.length ? sum + 1 : sum), 0)
  );
  return Math.max(...textsSizes);
};

function* showLastPageWarning(layout) {
  yield put(actionUI.setStateLegenda('POPUP'));
  yield put(
    actionUI.updatePopup(
      POPUP_ID_UNABLE_SET_LAST_PAGE_LAYOUT,
      true,
      popupMessage.UNABLE_SET_LAST_PAGE_LAYOUT(layout)
    )
  );
}

function* resetApplyMethod() {
  const state = yield select();
  const previousNav = getPreviousNav(state);
  const prodType = getProductType(state);
  const isModoSobFivela = getIsModoSobFivela(state);

  let value = 'somente a página atual';
  if (prodType === 'poster') value = 'somente ao Pôster atual';
  else if (prodType === 'deck-cards') {
    if (isModoSobFivela) value = 'a todas as cartas verso';
    else value = 'a todas as cartas frente';
  }

  if (previousNav === NAV_LAYOUTS_SELECTED)
    yield put(actionItem.resetLayoutFilterApplyMethod(value));
}

function* setLayoutPopup(photos, texts, pages, side) {
  const getPageIndex = ({ index, label }) => label || index;
  const checkSide = ({ index }) =>
    (side === 'RIGHT' && index % 2 !== 0) ||
    (side === 'LEFT' && index % 2 === 0) ||
    !side;

  const photo = R.map(
      getPageIndex,
      R.filter(
        checkSide,
        R.filter(({ frames }) => frames.length > photos, pages)
      )
    ),
    text = R.map(
      getPageIndex,
      R.filter(
        checkSide,
        R.filter(page => pagesHaveText([page]) > texts, pages)
      )
    );

  yield put(actionUI.setStateLegenda('POPUP'));
  yield put(actionUI.updatePopup(POPUP_ID_LAYOUT, true, { photo, text }));
  yield put(actionUI.updateSelectedNav(NAV_NONE_SELECTED));
}

function* setLayoutAux(page, shuffle = true) {
  const { result, entities } = normalizePage([page]);
  yield put(actionItem.addPages(result, entities));
  yield put(actionItem.updateLayoutFilterPages(page.index));
  if (page.frames.length && shuffle) {
    yield all(
      page.frames.map((frame, index) =>
        shuffleLayoutAux(
          frame.image.source.thumb.id,
          page.style.shape[index].id,
          page.index,
          page.id,
          `${page.style.id}`
        )
      )
    );
  }
}

const checkTextsLength = page =>
  R.reduce(
    (acc, { richTextState }) => (R.length(richTextState) ? acc + 1 : acc),
    0,
    R.prop('texts', page)
  );

function* shuffleLayout({ payload: { id, type } }) {
  const state = yield select();
  const IDBStatus = getIDBStatus(state);
  const styles = getProductStyles(state);
  const haveCover = bookHaveCover(state);
  const spineModified = isSpineModified(state);
  const prodType = getProductType(state);
  const { pages: pagesFiltered } = getLayoutsFilter(state);
  const page = R.find(R.propEq('id', id))(
    R.prop('pages', getBookNavigatorData(state))
  );
  const maxPage = getSetNumber(state);
  const isLastPageFlex =
    !haveCover && id === maxPage - 1 && samePageLayout(prodType);

  let styleIndex;
  const styleType = isLastPageFlex
    ? 'lastpage'
    : page.style.page
    ? 'page'
    : page.style.cover
    ? 'cover'
    : page.style.back_cover
    ? 'back_cover'
    : 'spine';

  const compareStyle = (a, b) => {
    if (a.shape === undefined || a.shape.length < b.shape.length) {
      return -1;
    }
    if (b.shape === undefined || b.shape.length > a.shape.length) {
      return 1;
    }
  };

  let stylesSorted = [...styles];

  stylesSorted = R.filter(s => s[styleType], stylesSorted);

  if (pagesFiltered.includes(page.index)) {
    // Filter shapes
    stylesSorted = R.filter(
      s => s.shape.length === page.style.shape.length,
      stylesSorted
    );

    // Filter text
    page.style.textarea
      ? (stylesSorted = R.filter(s => s.textarea, stylesSorted))
      : (stylesSorted = R.filter(s => !s.textarea, stylesSorted));
  } else {
    // Filter frames
    if (page.frames.length) {
      stylesSorted = R.filter(
        s => s.shape.length >= page.frames.length,
        stylesSorted
      );
    }

    // Filter text
    if (R.length(R.prop('texts', page)) && checkTextsLength(page)) {
      stylesSorted = R.filter(
        s => s.textarea && s.textarea.length >= checkTextsLength(page),
        stylesSorted
      );
    }
  }

  if (R.length(R.prop('texts', page)) > 1) {
    page.texts = R.sort((a, b) => {
      const textA = R.length(R.prop('richTextState', a));
      const textB = R.length(R.prop('richTextState', b));
      return textB > 0 ? 1 : textA > 0 ? -1 : 0;
    }, R.prop('texts', page));

    if (styleType === 'cover' && !spineModified) {
      const spineText = R.path(
        ['covers', 'spine', 'texts', 0],
        getBookNavigatorData(state)
      );
      const coverText = R.path(['texts', 0], page);
      if (coverText.richTextState !== spineText.richTextState) {
        yield put(
          actionGeneral.sagaSetRichText(
            spineText.id,
            'spine',
            R.prop('richTextState', coverText),
            false,
            true
          )
        );
      }
    }
  }

  stylesSorted.sort(compareStyle);

  stylesSorted.map((style, index) =>
    style.id === page.style.id ? (styleIndex = index) : null
  );

  if (type === 'INCREASE') {
    if (styleIndex === stylesSorted.length - 1) {
      page.style = R.head(stylesSorted);
    } else {
      page.style = stylesSorted[styleIndex + 1];
    }
  } else {
    if (styleIndex === 0) {
      page.style = R.last(stylesSorted);
    } else {
      page.style = stylesSorted[styleIndex - 1];
    }
  }

  if (styleType !== 'spine') {
    const { result, entities } = normalizePage([page]);
    yield put(actionItem.addPages(result, entities));
  }

  // If the old layout have image
  if (page.frames.length > 0) {
    yield all(
      page.frames.map((frame, index) =>
        shuffleLayoutAux(
          frame.image.source.thumb.id,
          page.style.shape[index].id,
          page.index,
          page.id,
          `${page.style.id}`
        )
      )
    );
  }
  if (IDBStatus) {
    yield idbUpdateMetadata();
  }
}

function* shuffleLayoutAux(thumbId, shapeId, pageIndex, pageId, styleId) {
  yield delay(1);
  yield put(
    actionGeneral.sagaThumbDropImage(
      thumbId,
      shapeId,
      pageIndex,
      pageId,
      styleId,
      false,
      true
    )
  );
}

function* updateThumbExpand({ payload: { type } }) {
  const state = yield select();
  const { id } = getThumbExpand(state);
  const images = getFilteredSortedImgs(state);
  const thumbImageIndex = R.findIndex(R.propEq('id', id))(images);

  if (images.length > 0) {
    switch (type) {
      case PREVIOUS:
        yield put(
          actionUI.updateThumbExpand(
            'CLICK',
            images[
              thumbImageIndex === 0 ? images.length - 1 : thumbImageIndex - 1
            ].id
          )
        );
        break;
      case NEXT:
        yield put(
          actionUI.updateThumbExpand(
            'CLICK',
            images[
              thumbImageIndex + 1 === images.length ? 0 : thumbImageIndex + 1
            ].id
          )
        );
        break;
      default:
        break;
    }
  }
}

function* watchFetchOrder() {
  yield takeLatest(SAGA_FETCH_DATA, fetchOrder);
}

function* watchStartMetadataLoop() {
  yield takeLatest(SAGA_START_METADATA_LOOP, postMetadataLoop);
}

function* watchPostMetadata() {
  yield takeLatest(SAGA_POST_METADATA, postMetadata);
}

function* watchStartImageLoop() {
  yield takeLatest(SAGA_START_IMAGE_LOOP, postImageLoop);
}

function* watchPostImage() {
  yield takeEvery(SAGA_POST_IMAGE, postImage);
}

function* watchSubmit() {
  yield takeLatest(SAGA_SUBMIT, submitOrder);
}

function* watchInputFileChange() {
  yield takeLatest(SAGA_INPUT_FILE_CHANGED, loadImageFiles);
}

function* watchAddThemeImage() {
  yield takeLatest(SAGA_ADD_THEME_IMAGE, addThemeImages);
}

function* watchThumbDropImage() {
  yield takeEvery(SAGA_THUMB_DROP_IMAGE, thumbDropImage);
}

function* watchInsertPages() {
  yield takeLatest(SAGA_INSERT_PAGES, insertPages);
}

function* watchRemovePages() {
  yield takeLatest(SAGA_REMOVE_PAGES, removePages);
}

function* watchRemoveTexts() {
  yield takeLatest(SAGA_REMOVE_TEXTS, removeTexts);
}

function* watchDeleteImageFromBook() {
  yield takeEvery(SAGA_DELETE_IMAGE_FROM_BOOK, deleteImageFromBook);
}

function* watchSwapPoster() {
  yield takeEvery(SAGA_SWAP_POSTER, swapPoster);
}

function* watchOverviewRemovePages() {
  yield takeLatest(SAGA_OVERVIEW_REMOVE_PAGES, overviewRemovePages);
}

function* watchUnstageImage() {
  yield takeLatest(SAGA_UNSTAGE_STAGE_IMAGE, unstageImage);
}

function* watchIncreasePageIndex() {
  yield takeLatest(SAGA_INCREASE_PAGE_INDEX, increasePageIndex);
}

function* watchDecreasePageIndex() {
  yield takeLatest(SAGA_DECREASE_PAGE_INDEX, decreasePageIndex);
}

function* watchMovePage() {
  yield takeLatest(SAGA_MOVE_PAGE, movePage);
}

function* watchDuplicatePage() {
  yield takeLatest(DUPLICATE_PAGE, duplicatePage);
}

function* watchSetPageIndex() {
  yield takeLatest(SAGA_SET_PAGE_INDEX, setPageIndex);
}

function* watchFirstPage() {
  yield takeLatest(SAGA_FIRST_PAGE_INDEX, setFirstPage);
}

function* watchLastPage() {
  yield takeLatest(SAGA_LAST_PAGE_INDEX, setLastPage);
}

function* watchLastPageLayout() {
  yield takeLatest(UPDATE_PAGE_SET_ID, setLastPageLayout);
}

function* watchAdjustLayoutsFilterPhotoNumber() {
  yield takeLatest(
    SAGA_UPDATE_LAYOUT_FILTER_PHOTO_NUMBER,
    adjustLayoutsFilterPhotoNumber
  );
}

function* watchAdjustLayoutsFilter() {
  yield takeLatest(SAGA_ADJUST_LAYOUTS_FILTER, adjustLayoutsFilter);
}

function* watchUpdateLayoutFilter() {
  yield takeLatest(UPDATE_LAYOUT_FILTER_PHOTO_NUMBER, updateLayoutFilterAux);
  yield takeLatest(UPDATE_LAYOUT_FILTER_TEXT, updateLayoutFilterAux);
  yield takeLatest(UPDATE_LAYOUT_FILTER_APPLY_METHOD, updateLayoutFilterAux);
}

function* watchSetLayout() {
  yield takeLatest(SAGA_SET_LAYOUT, setLayout);
}

function* watchNavUpdate() {
  yield takeLatest(UPDATE_NAV_SELECTED, resetApplyMethod);
}

function* watchShuffleLayout() {
  yield takeLatest(SAGA_SHUFFLE_LAYOUT, shuffleLayout);
}

function* watchRichTextUpdate() {
  yield takeEvery(SAGA_SET_RICH_TEXT, richTextUpdate);
}

function* watchSagaUpdateThumbExpand() {
  yield takeLatest(SAGA_UPDATE_THUMB_EXPAND, updateThumbExpand);
}

function* watchDeleteAllImages() {
  yield takeLatest(DELETE_ALL_IMAGES, updateImagesToUpload);
}

function* watchPostMetadataFormat() {
  yield takeLatest(SAGA_POST_METADATA_FORMAT, postMetadataFormat);
}

function* watchPostMetadataOrientation() {
  yield takeLatest(SAGA_POST_METADATA_ORIENTATION, postMetadataOrientation);
}

function* watchUIUpdate() {
  const state = yield select();
  const IDBStatus = getIDBStatus(state);
  if (IDBStatus) {
    // Navegação na barra de fotos
    yield takeLatest(UPDATE_TOOLBAR_PHOTO, idbUpdateMetadata);
    // Overview fotos e livro
    yield takeLatest(UPDATE_GALLERY_EXPANDED, idbUpdateMetadata);
    yield takeLatest(UPDATE_BOOK_OVERVIEW_EXPANDED, idbUpdateMetadata);
    //Modo de visualização Nomrmal/Impressao
    yield takeLatest(TOGGLE_MODO_IMPRESSAO, idbUpdateMetadata);
    yield takeLatest(TOGGLE_BIBLIOTECA_TEMAS, idbUpdateMetadata);
    yield takeLatest(SET_MODAL_CONFIG, idbUpdateMetadata);
    // Photo editor
    yield takeLatest(UPDATE_PHOTO_EDITOR, idbUpdateMetadata);
    yield takeLatest(UPDATE_ZOOM, idbUpdateMetadata);
    yield takeLatest(ROTATE_IMAGE, idbUpdateMetadata);
    yield takeLatest(SET_STOP_DRAG, idbUpdateMetadata);
    // Opções imagens
    yield takeLatest(UPDATE_FILTER_METHOD, idbUpdateMetadata);
    yield takeLatest(UPDATE_SORT_METHOD, idbUpdateMetadata);
    yield takeLatest(DELETE_ALL_IMAGES, idbUpdateMetadata);
    // Quantidade paginas
    yield takeLatest(UPDATE_PAGE_IDB, idbUpdateMetadata);
    // ThumbExpand
    yield takeLatest(UPDATE_THUMB_EXPAND_IDB, idbUpdateMetadata);
  }
}

export default function* rootSaga() {
  yield all([
    watchFetchOrder(),
    watchStartMetadataLoop(),
    watchPostMetadata(),
    watchStartImageLoop(),
    watchPostImage(),
    watchSubmit(),
    watchInputFileChange(),
    watchAddThemeImage(),
    watchThumbDropImage(),
    watchInsertPages(),
    watchRemovePages(),
    watchRemoveTexts(),
    watchDeleteImageFromBook(),
    watchSwapPoster(),
    watchOverviewRemovePages(),
    watchUnstageImage(),
    watchIncreasePageIndex(),
    watchDecreasePageIndex(),
    watchMovePage(),
    watchDuplicatePage(),
    watchSetPageIndex(),
    watchRichTextUpdate(),
    watchFirstPage(),
    watchLastPage(),
    watchLastPageLayout(),
    watchAdjustLayoutsFilter(),
    watchAdjustLayoutsFilterPhotoNumber(),
    watchUpdateLayoutFilter(),
    watchSetLayout(),
    watchNavUpdate(),
    watchShuffleLayout(),
    watchSagaUpdateThumbExpand(),
    watchDeleteAllImages(),
    watchUIUpdate(),
    watchPostMetadataFormat(),
    watchPostMetadataOrientation()
  ]);
}
