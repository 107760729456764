import * as R from 'ramda';
import * as actionGeneral from '../constant/action-type/general';

import merge from 'lodash/merge';
import calculoRotate from '../utils/calculoRotate';

const initalState = {
  image: {},
  products: [],
  revisarMudancaOrientacao: false
};

const reducer = (state = initalState, action) => {
  if (action.mergeLib) {
    return merge(state, action.payload.entities);
  }

  switch (action.type) {
    case actionGeneral.FETCH_FROM_IDB:
      return {
        ...state,
        ...action.payload.library
      };
    case actionGeneral.UPDATE_BLOB_URL:
      return {
        ...state,
        source: {
          ...state.source,
          [action.payload.id]: {
            ...state.source[action.payload.id],
            blobUrl: action.payload.url
          }
        }
      };
    case actionGeneral.DELETE_ALL_IMAGES:
      return {
        ...state,
        image: R.map(
          i => (i.used === 0 ? R.assoc('staged', false, i) : i),
          state.image
        )
      };
    case actionGeneral.MOVE_IMAGE:
      return {
        ...state,
        frame: {
          ...state.frame,
          [action.payload.id]: {
            ...state.frame[action.payload.id],
            posX:
              action.payload.posX !== false
                ? action.payload.posX
                : state.frame[action.payload.id].posX,
            posY:
              action.payload.posY !== false
                ? action.payload.posY
                : state.frame[action.payload.id].posY
          }
        }
      };
    case actionGeneral.UPDATE_ZOOM:
      let zoom = state.frame[action.payload.id].zoom || 1;

      if (action.payload.type === 'INCREASE' && zoom < 2) {
        zoom += 0.05;
      } else if (action.payload.type === 'DECREASE' && zoom > 1) {
        zoom -= 0.05;
      }

      return {
        ...state,
        frame: {
          ...state.frame,
          [action.payload.id]: {
            ...state.frame[action.payload.id],
            zoom
          }
        }
      };
    case actionGeneral.ROTATE_IMAGE:
      const rotate = calculoRotate(
        state.frame[action.payload.id].rotate,
        action.payload.quantidade
      );
      return {
        ...state,
        frame: {
          ...state.frame,
          [action.payload.id]: {
            ...state.frame[action.payload.id],
            rotate
          }
        }
      };
    case actionGeneral.SAGA_REMOVE_FRAME:
      return {
        ...state,
        frame: R.filter(frame => action.payload.id !== frame.id, state.frame)
      };
    case actionGeneral.REMOVE_FRAME_FROM_PAGE:
      return {
        ...state,
        page: {
          ...state.page,
          [action.payload.pageId]: {
            ...state.page[action.payload.pageId],
            frames: R.filter(
              frame => action.payload.frameId !== frame,
              state.page[action.payload.pageId].frames
            )
          }
        }
      };
    case actionGeneral.REMOVE_TEXTS_FROM_PAGE:
      return {
        ...state,
        page: {
          ...state.page,
          [action.payload.pageId]: {
            ...state.page[action.payload.pageId],
            texts: []
          }
        }
      };
    case actionGeneral.SET_STAGE_IMAGE:
      return {
        ...state,
        image: {
          ...state.image,
          [action.payload.id]: {
            ...state.image[action.payload.id],
            staged: action.payload.isStaged
          }
        }
      };
    case actionGeneral.SET_UPLOAD_IMAGE:
      return {
        ...state,
        image: {
          ...state.image,
          [action.payload.id]: {
            ...state.image[action.payload.id],
            uploaded: action.payload.isUploaded
          }
        }
      };
    case actionGeneral.UPDATE_USED_IMAGE:
      return {
        ...state,
        image: {
          ...state.image,
          [action.payload.id]: {
            ...state.image[action.payload.id],
            used:
              action.payload.type === 'INCREASE'
                ? state.image[action.payload.id].used + 1
                : action.payload.type === 'DECREASE'
                ? state.image[action.payload.id].used - 1
                : 0
          }
        }
      };
    case actionGeneral.REMOVE_PAGES:
      const keys = Object.keys(state.page);
      for (const key of keys) {
        state.page[key].frames = [];
      }
      return {
        ...state,
        frame: {}
      };
    case actionGeneral.SET_CUSTOM_PRODUCTS:
      return {
        ...state,
        products: action.payload.products
      };
    case actionGeneral.TOGGLE_REVISAR_MUDANCA_ORIENTACAO:
      return {
        ...state,
        revisarMudancaOrientacao: !state.revisarMudancaOrientacao
      };
    default:
      return state;
  }
};

export default reducer;
