import { oneCols } from '../utils/separadorProdutos';

export const ajustePaddingOverview = 0.015;
const ajusteWidthPorcentage = 0.97;
// const ajusteWidth = 17;
const ajustePaddingRight = 0.984;
// const ratioDogCollar = 198/1969;
// const tamanhoMedio = 290/420;
// const tamanhoGrande = 290/560;
const tamanhoMedio = 1;
const tamanhoGrande = 1;

export function calcularPhotobookWidth(width, height) {
  // return document.documentElement.scrollHeight !== height
  //     ? width * ajusteWidthPorcentage
  //     : width * ajusteWidthPorcentage + ajusteWidth;
  return width * ajusteWidthPorcentage;
}

export function calcularPhotobookHeight(prodType, isPrint, height) {
  return height;
}

export function calcularWidthViewBox(
  isThumb,
  prodType,
  finalPageWidth,
  finalCol
) {
  let width = finalPageWidth * finalCol;
  return width;
}

export function calcularHeightViewBox(
  isThumb,
  prodType,
  finalPageHeight,
  lines
) {
  let height = finalPageHeight * lines;
  return height;
}

export function calcularWidthBookNavigatorPrint(
  isThumb,
  isCover,
  isSpineIndex,
  prodType,
  pdfWidth,
  pdfCoverWidth,
  pdfSpineWidth,
  pdfBleed,
  coverLengthPDF,
  tamanhoColeira,
  width,
  isBookOverviewExpanded,
  screenSize
) {
  let widthPrint;
  if (isCover && !isThumb)
    widthPrint = `${pdfCoverWidth +
      (isSpineIndex && coverLengthPDF > 1 && !isThumb ? pdfSpineWidth : 0)}mm`;
  else if (prodType === 'dog-collar') {
    if (isThumb) {
      let auxWidth = 201;
      return `${auxWidth}mm`;
    } else {
      let auxWidth = 200;
      switch (tamanhoColeira) {
        case 19:
          auxWidth = 210;
          break;
        case 20:
          auxWidth = 220;
          break;
        case 21:
          auxWidth = 230;
          break;
        case 22:
          auxWidth = 240;
          break;
        case 23:
          auxWidth = 250;
          break;
        case 24:
          auxWidth = 260;
          break;
        case 25:
          auxWidth = 270 * tamanhoMedio;
          break;
        case 26:
          auxWidth = 280 * tamanhoMedio;
          break;
        case 27:
          auxWidth = 290 * tamanhoMedio;
          break;
        case 28:
          auxWidth = 300 * tamanhoMedio;
          break;
        case 29:
          auxWidth = 310 * tamanhoMedio;
          break;
        case 30:
          auxWidth = 320 * tamanhoMedio;
          break;
        case 31:
          auxWidth = 330 * tamanhoMedio;
          break;
        case 32:
          auxWidth = 340 * tamanhoGrande;
          break;
        case 33:
          auxWidth = 350 * tamanhoGrande;
          break;
        case 34:
          auxWidth = 360 * tamanhoGrande;
          break;
        case 35:
          auxWidth = 370 * tamanhoGrande;
          break;
        case 36:
          auxWidth = 380 * tamanhoGrande;
          break;
        case 37:
          auxWidth = 390 * tamanhoGrande;
          break;
        case 38:
          auxWidth = 400 * tamanhoGrande;
          break;
        case 39:
          auxWidth = 410 * tamanhoGrande;
          break;
        case 40:
          auxWidth = 420 * tamanhoGrande;
          break;
        case 41:
          auxWidth = 430 * tamanhoGrande;
          break;
        case 42:
          auxWidth = 440 * tamanhoGrande;
          break;
        case 43:
          auxWidth = 450 * tamanhoGrande;
          break;
        case 44:
          auxWidth = 460 * tamanhoGrande;
          break;
        case 45:
          auxWidth = 470 * tamanhoGrande;
          break;
        case 46:
          auxWidth = 480 * tamanhoGrande;
          break;
        case 47:
          auxWidth = 490 * tamanhoGrande;
          break;
        case 48:
          auxWidth = 500 * tamanhoGrande;
          break;
        case 49:
          auxWidth = 510 * tamanhoGrande;
          break;
        case 50:
          auxWidth = 520 * tamanhoGrande;
          break;
        case 51:
          auxWidth = 530 * tamanhoGrande;
          break;
        case 52:
          auxWidth = 540 * tamanhoGrande;
          break;
        case 53:
          auxWidth = 550 * tamanhoGrande;
          break;
        case 54:
          auxWidth = 560 * tamanhoGrande;
          break;
        default:
          auxWidth = 200;
          break;
      }
      widthPrint = `${auxWidth}mm`;
    }
  } else
    widthPrint = `${pdfWidth +
      ((isThumb && prodType === 'calendar') || prodType === 'mask'
        ? 0
        : pdfBleed * 2) +
      (isCover && isThumb ? pdfBleed * 2 : 0)}mm`;

  return widthPrint;
}

export function calcularHeightBookNavigatorPrint(
  isThumb,
  isCover,
  prodType,
  pdfHeight,
  pdfCoverHeight,
  pdfBleed,
  tamanhoColeira,
  height
) {
  let heightPrint;
  if (isCover && !isThumb) heightPrint = `${pdfCoverHeight}mm`;
  else if (prodType === 'dog-collar') {
    if (isThumb) {
      let auxHeight = 212;
      return `${auxHeight}mm`;
    } else {
      let auxHeight = pdfHeight;
      switch (tamanhoColeira) {
        case 25:
        case 26:
        case 27:
        case 28:
        case 29:
        case 30:
        case 31:
          auxHeight = pdfHeight * tamanhoMedio;
          break;
        case 32:
        case 33:
        case 34:
        case 35:
        case 36:
        case 37:
        case 38:
        case 39:
        case 40:
        case 41:
        case 42:
        case 43:
        case 44:
        case 45:
        case 46:
        case 47:
        case 48:
        case 49:
        case 50:
        case 51:
        case 52:
        case 53:
        case 54:
          auxHeight = pdfHeight * tamanhoGrande;
          break;
        default:
          auxHeight = pdfHeight;
          break;
      }
      heightPrint = `${auxHeight}mm`;
    }
  } else
    heightPrint = `${pdfHeight +
      (isThumb && prodType === 'calendar'
        ? 0
        : prodType !== 'mask'
        ? pdfBleed * 2
        : 0)}mm`;
  return heightPrint;
}

export function photobookPadding(prodType, screenSize, isBookOverviewExpanded) {
  let aux = '0px';
  if (isBookOverviewExpanded && prodType !== 'dog-collar')
    aux = `${screenSize.width * 0.016 - 10}px ${screenSize.width *
      ajustePaddingOverview}px 0 ${screenSize.width * ajustePaddingOverview}px`;
  return aux;
}

export function photobookJustify(prodType, isBookOverviewExpanded) {
  let aux = 'center';
  if (isBookOverviewExpanded && prodType !== 'dog-collar') aux = 'flex-start';
  return aux;
}

export function calculaWidthProduto(
  width,
  prodType,
  isBookOverviewExpanded,
  screenSizeWidth
) {
  let aux = width;
  if (prodType === 'dog-collar' && isBookOverviewExpanded) {
    aux = screenSizeWidth * ajustePaddingRight;
  }
  return aux;
}

export function calculaHeightProduto(prodType, height) {
  let aux = height;
  if (prodType === 'dog-collar') aux = '100%';
  return aux;
}

export function calculaFinalPageWidth(
  isThumb,
  pageWidth,
  prodType,
  isPrint,
  isCover,
  debru,
  bleed,
  tamanhoSelecionado
) {
  let additional = isCover
    ? debru
    : isPrint && prodType !== 'mask'
    ? bleed * 2
    : 0;

  let finalPageWidth = isThumb ? pageWidth : pageWidth + additional;

  if (prodType === 'dog-collar') {
    switch (tamanhoSelecionado) {
      case 19:
        finalPageWidth = 2067;
        break;
      case 20:
        finalPageWidth = 2165;
        break;
      case 21:
        finalPageWidth = 2264;
        break;
      case 22:
        finalPageWidth = 2362;
        break;
      case 23:
        finalPageWidth = 2461;
        break;
      case 24:
        finalPageWidth = 2559;
        break;
      case 25:
        finalPageWidth = 2657;
        break;
      case 26:
        finalPageWidth = 2756;
        break;
      case 27:
        finalPageWidth = 2854;
        break;
      case 28:
        finalPageWidth = 2953;
        break;
      case 29:
        finalPageWidth = 3051;
        break;
      case 30:
        finalPageWidth = 3150;
        break;
      case 31:
        finalPageWidth = 3248;
        break;
      case 32:
        finalPageWidth = 3346;
        break;
      case 33:
        finalPageWidth = 3445;
        break;
      case 34:
        finalPageWidth = 3543;
        break;
      case 35:
        finalPageWidth = 3642;
        break;
      case 36:
        finalPageWidth = 3740;
        break;
      case 37:
        finalPageWidth = 3839;
        break;
      case 38:
        finalPageWidth = 3937;
        break;
      case 39:
        finalPageWidth = 4035;
        break;
      case 40:
        finalPageWidth = 4134;
        break;
      case 41:
        finalPageWidth = 4232;
        break;
      case 42:
        finalPageWidth = 4331;
        break;
      case 43:
        finalPageWidth = 4429;
        break;
      case 44:
        finalPageWidth = 4528;
        break;
      case 45:
        finalPageWidth = 4626;
        break;
      case 46:
        finalPageWidth = 4724;
        break;
      case 47:
        finalPageWidth = 4823;
        break;
      case 48:
        finalPageWidth = 4921;
        break;
      case 49:
        finalPageWidth = 5020;
        break;
      case 50:
        finalPageWidth = 5118;
        break;
      case 51:
        finalPageWidth = 5217;
        break;
      case 52:
        finalPageWidth = 5315;
        break;
      case 53:
        finalPageWidth = 5413;
        break;
      case 54:
        finalPageWidth = 5512;
        break;
      default:
        finalPageWidth = pageWidth;
        break;
    }
  }

  return finalPageWidth;
}

export function calculaFinalPageHeight(
  isThumb,
  isPrint,
  isCover,
  productType,
  pageHeight,
  debru,
  bleed
) {
  let finalPageHeight = isThumb
    ? pageHeight
    : pageHeight +
      (isCover ? debru * 2 : isPrint && productType !== 'mask' ? bleed * 2 : 0);
  return finalPageHeight;
}

export function calculaLastNavigatorOnLine(
  prodType,
  bookHaveCover,
  maxNavigatorLine,
  i
) {
  let aux = 1;

  if (!oneCols.includes(prodType))
    aux = (i + (bookHaveCover ? 2 : 1)) % maxNavigatorLine === 0 ? 0 : 1;

  return aux;
}

export function isPaginaVazia(pagina) {
  let vazia = true;

  if (
    (pagina.texts != null &&
      pagina.texts.length > 0 &&
      pagina.texts.find(t => t.richTextState.length > 0) != null) ||
    pagina.frames.length > 0
  )
    vazia = false;

  return vazia;
}

export const calcularPosicaoinicialImagem = (
  shape,
  imgWidth,
  imgHeight,
  bleed
) => {
  const shapeWidth = shape.width;
  const shapeHeight = shape.height;
  const rateW = parseFloat((shapeWidth / imgWidth).toFixed(2));
  const rateH = parseFloat((shapeHeight / imgHeight).toFixed(2));
  let x = shape.left === 0 ? -bleed : 0;
  let y = shape.top === 0 ? -bleed : 0;

  // Center frame
  if (rateW > rateH) {
    const finalHeight = imgHeight * rateW - shapeHeight;
    const rateY = Math.floor(-finalHeight / 2);
    y += rateY;
  }
  if (rateH > rateW) {
    const finalWidth = imgWidth * rateH - shapeWidth;
    const rateX = Math.floor(-finalWidth / 2);
    x += rateX;
  }

  return [x, y];
};

export function tamanhoDefaulltTexto(prodType) {
  let size = 16;
  if (prodType === 'deck-cards') size = 8;
  return size;
}

export function backgroundPage(prodType, active, fundoColor) {
  let color = active ? '#3f3f3f' : '#fff';
  if (prodType === 'deck-cards') color = fundoColor;
  return color;
}

export const verificaTextoVazio = page => {
  let textoVazio = false;
  if (page.style.textarea == null || page.style.textarea.length === 0)
    textoVazio = true;
  else
    textoVazio = page.style.textarea.every(
      (_textarea, index) =>
        page.texts == null ||
        page.texts.length < index ||
        (page.texts.length >= index &&
          (page.texts[index].richTextState == null ||
            page.texts[index].richTextState.length <= 0))
    );

  return textoVazio;
};

export const getShuffleLayoutActive = (prodType, page, layoutsPage) => {
  let shuffleLayoutActive = true;
  const layoutSelected = page && page.style && page.style.id;
  const indexpage = page && page.index;
  if (
    prodType === 'deck-cards' &&
    layoutSelected &&
    verificaLayoutVazio(layoutSelected) &&
    layoutsPage &&
    layoutsPage.length > 0 &&
    layoutsPage.includes(indexpage)
  )
    shuffleLayoutActive = false;
  return shuffleLayoutActive;
};

export const verificaLayoutVazio = layout => {
  let layoutVazio = false;
  if (layout === 10073741) layoutVazio = true;
  return layoutVazio;
};

export const findEmptyStyle = styles => {
  let style = styles.find(({ id }) => verificaLayoutVazio(id));
  if (!style) style = styles[0];
  return style;
};

const layoutsFrente = [10073741, 10073674, 10073675, 10073676];

export const selectLayoutsFrente = (prodType, isModoSobFivela, styles) => {
  let layouts = styles;
  if (prodType === 'deck-cards' && !isModoSobFivela) {
    layouts = [];
    styles.forEach(style => {
      if (layoutsFrente.includes(style.id)) layouts.push(style);
    });
  }
  return layouts;
};

export const geraPagesMsgDeckCards = pagesNotCompleteFilled => {
  let pagesMsg = '';
  if (pagesNotCompleteFilled.length > 1)
    pagesMsg = '01 (Carta Frente) e 01 (Carta Verso)';
  else if (pagesNotCompleteFilled.length > 0)
    pagesMsg =
      pagesNotCompleteFilled[0] === 1
        ? '01 (Carta Frente)'
        : '01 (Carta Verso)';
  return pagesMsg;
};

export const geraPageNotCompletedInvitation = (
  faltaImagem,
  textos,
  imagens
) => {
  let page = 0;
  const conviteVerso = JSON.stringify([2]);
  const arrayToCompare = faltaImagem
    ? JSON.stringify(imagens)
    : JSON.stringify(textos);
  if (arrayToCompare === conviteVerso) page = 1;
  return page;
};

export const removerChavesObjeto = (obj, chaves) => {
  const newObj = Object.entries(obj)
    .filter(entry => !chaves.includes(entry[0]))
    .reduce((acc, keyValue) => ({ ...acc, [keyValue[0]]: keyValue[1] }), {});
  return newObj;
};

export const calcCustomSize = size => Math.round(Number(size) / 4);

export const getFamilyArray = (prodType, textOptions) => {
  let familyArray = textOptions.familyArray;
  if (prodType === 'dog-collar') familyArray = textOptions.familyArrayDogCollar;
  else if (prodType === 'invitation')
    familyArray = textOptions.familyInvitationArray;
  return familyArray;
};

export const getQueryParams = () => {
  const urlParams = window.location.search.slice(1).split('&');
  const params = {};

  urlParams.forEach(param => {
    const values = param.split('=');
    values.length > 1
      ? (params[values[0]] = values[1])
      : (params[values[0]] = true);
  });

  return params;
};

export const isFix = () => {
  const urlParams = window.location.search.slice(1).split('&');
  return urlParams.includes('fix');
};
